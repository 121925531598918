<template>
  <div class="container-fluid" v-if="$route.meta.requiresAuth" @click="checkSessionTimeout">
    <div class="row">
      <AppSlideTab />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
        <div class="footer-bg">
          <div class="footer-bold">Copyright <i class="far fa-copyright" aria-hidden="true"></i> {{ new
    Date().getFullYear() }} All rights reserved by edynamics Tech Solutions Pvt. Ltd.</div>
          <!-- <div class="footer-small">Concept Inspiration: Visionary Leader Shri Raj Thakare (श्री. राजसाहेब ठाकरे)</div> -->
        </div>
      </main>
    </div>
  </div>
  <div class="container-fluid" v-if="!$route.meta.requiresAuth">
    <router-view />
  </div>
  <ReLoginModal v-if="showReloginPopup" />
</template>
<script>
import AppSlideTab from "./components/SlideTabs.vue";
import Header from "./components/Header.vue";
import ReLoginModal from "./components/ReLogin.vue";
import moment from "moment";
import ApiService from "./service/ApiService";
import useValidate from "@vuelidate/core";
export default {
  components: {
    AppSlideTab: AppSlideTab,
    Header: Header,
    ReLoginModal: ReLoginModal,
  },
  data() {
    return {
      v$: useValidate(),
      showReloginPopup: false,
      lockstatus: '',
      lockstatusreason: '',
    };
  },
  mounted() {
    if (
      localStorage.error_toaster_display &&
      localStorage.error_toaster_display == 1
    ) {
      this.$toast.open({
        message: localStorage.logout_reason,
        type: "error",
        duration: 3000,
        position: "top-right",
      });
      localStorage.clear();
    }

    if (localStorage.userLockAttempt == 1) {
      this.lockstatus = localStorage.userLockAttempt;
      this.lockstatusreason = localStorage.userLockAttemptReason;
    }
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },

  methods: {
    checkSessionTimeout() {
      if (
        localStorage.last_login_time &&
        localStorage.last_login_time != null
      ) {
        let now = new Date().toUTCString();
        var diff =
          (moment.utc(now) - moment.utc(localStorage.last_login_time)) / 1000;
        diff /= 60;
        diff = Math.abs(Math.round(diff));
        if (diff >= 60) {
          localStorage["logoutUser"] = 1;
          localStorage["sessionStandBy"] = 0;
          this.$toast.open({
            message:
              "Your session expired as you were inactive for more than 60 minutes. Please login again to use Lok Neta",
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          setTimeout(() => {
            this.logoutOnSessionTimeOut();
          }, 1000);
        } else {
          var currentTime = new Date().toUTCString();
          localStorage["last_login_time"] = currentTime;
        }
      }
      if (localStorage.userLockAttempt == 1) {
        
        var errorMsg = this.lockstatusreason;
        this.$toast.open({
          message: errorMsg,
          type: "error",
          duration: 30000,
          position: "top",
        });
        setTimeout(() => {
          this.logoutuser();
        }, 10000);
      }
    },
    logoutOnSessionTimeOut() {
      localStorage["sessionStandBy"] = 0;
      this.ApiService.logoutonsessiontimeout({ logout_reason: 3 }).then(
        (data) => {
          if (data.status == 200) {
            // var userData = JSON.parse(localStorage.user);
            // this.userloginid = userData.ak24;
            // this.userfullname = userData.user_short_name;
            localStorage["logoutUser"] = 1;
            this.showReloginPopup = true;
          }
        }
      );
    },
    logoutuser() {
      this.ApiService.logout().then((data) => {
        if (data.status == 200) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        }
      });
    },
  },
};
</script>
<style scoped>
.modal-content.modal-outer {
  padding: 10px;
}

.horizontal-line {
  border: 1px solid #ebeef5;
  opacity: 1;
}

.custom-user-name {
  letter-spacing: 0.32px;
  color: #2b2e38;
  opacity: 1;
  font-size: 20px;
  font-family: "AcuminPro-Regular";
}

.custom-user-content {
  letter-spacing: 0.26px;
  color: #4a5463;
  opacity: 1;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
}

.modal-dialog-scrollable .modal-content {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #e9e9e4;
}

.modal-dialog-scrollable .modal-content::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.modal-dialog-scrollable .modal-content::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-form-group .custom-error {
  color: #de2525;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.52px;
  line-height: 20px;
  position: absolute;
}

.custom-form-group {
  margin-bottom: 40px;
}

.custom-logo {
  background: #2a2a45 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #000;
  padding: 12px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 50px;
}

.modal-step-next-btns.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  min-width: 150px;
  width: 100%;
}

.modal-step-next-btns.btn:focus,
.modal-step-next-btns.btn:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.custom-img-outer {
  height: 100%;
  background-color: #ababab;
}

.custom-img-outer img {
  width: 100%;
  height: 100%;
}

.custom-card-outer {
  background-color: #ffffff;
}
</style>