<template>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="custom-form-group">
                <label class="intelligent-campagin-modal-header">SMS Campaign</label>
            </div>
        </div>
    </div>
    <div class="campaign-tab-outer-scroll" :style="{ height: '409px' }">
        <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Sender ID / Header<span class="text-danger">*</span></label>
                    <Multiselect v-model="senderid" :options="senderidlist" :searchable="true" label="label"
                        placeholder="Select Sender ID / Header" class="multiselect-custom text-capitalize" :canClear="true"
                        :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                    <div class="custom-error" v-if="v$.senderid.$error">
                        {{ v$.senderid.$errors[0].$message }}
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Vendor Template ID<span
                            class="text-danger">*</span></label>
                    <input type="text" v-model="vendortemplateid" class="form-control"
                        placeholder="Enter Vendor Template ID" @paste="onPasteMobile" @keypress="onlyNumber"
                        autocomplete="off" />
                    <div class="custom-error" v-if="v$.vendortemplateid.$error">
                        {{ v$.vendortemplateid.$errors[0].$message }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="card-template">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Template Name <span class="text-danger">*</span></label>
                                        <input type="text" v-model="templatename" v-on:keyup="checkTemplateName()" class="form-control" placeholder="Enter Template Name"
                                            maxlength="250" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.templatename.$error">
                                            {{ v$.templatename.$errors[0].$message }}
                                        </div>
                                        <div class="custom-error" v-if="duplicateTemplateMsg">
                                            {{ duplicateTemplateMsg }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Alias Name <span class="text-danger">*</span></label>
                                        <input type="text" v-model="aliasname" class="form-control" placeholder="Enter Alias Name"
                                            maxlength="250" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.aliasname.$error">
                                            {{ v$.aliasname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="card-template mt-3">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label for="formmessageInput" class="form-label">Message <span class="text-danger">*</span></label>
                                        <textarea type="text" v-model="smsmessage" class="form-control" id="formmessageInput"
                                            placeholder="Enter Message" v-on:keyup="htmlconvertmsg()" autocomplete="off" rows="3" maxlength="1024"
                                            :style="{ resize: 'vertical' }"></textarea>
                                        <div class="custom-error" v-if="v$.smsmessage.$error">
                                            {{ v$.smsmessage.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="custom-form-group text-center">
                                    <button type="button" class="btn modal-bulk-next-btn" @click="smsSubmitBtn()" :disabled="smsgbtnloader">
                                        <span v-if="!smsgbtnloader">Submit</span>
                                        <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="smsgbtnloader">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="intelligent-campagin-modal-header mb-2 text-center">Message Preview</div>
                    </div>
                </div>
                <div class="preview-card-template">
                    <div class="preview-body-msg" v-if="smsmessage != ''">
                        <p v-html="this.smsmessageinput"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
export default {
    data() {
        return {
            v$: useValidate(),
            smsgbtnloader: false,
            senderid: '',
            senderidlist: [],
            vendortemplateid: '',
            templatename: '',
            aliasname: '',
            smsmessage: '',
            smsmessageinput: '',
            duplicateTemplateMsg: '',
        };
    },
    validations() {
        return {
            senderid: {
                required: helpers.withMessage("Please select sender id", required),
            },
            vendortemplateid: {
                required: helpers.withMessage("Please enter vendor template id", required),
                minLength: helpers.withMessage(
            "Template id should be min 10 length",
            minLength(10)
          ),
            },
            templatename: {
                required: helpers.withMessage("Please enter template name", required),
            },
            aliasname: {
                required: helpers.withMessage("Please enter alias name", required),
            },
            smsmessage: {
                required: helpers.withMessage("Please enter message", required),
            },
            
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getSenderIdList();
    },
    methods: {
        getSenderIdList(e) {
            this.ApiService.getSenderIdList(e).then((data) => {
                if (data.status == 200) {
                    this.senderidlist = data.data;
                } else {
                    this.senderidlist = [];
                }
            });
        },
        smsSubmitBtn() {
            this.v$.$validate();
            let fields = {};
            if(this.senderid) {
                fields["cj2"] = this.senderid.value;
                fields["cj3"] = this.senderid.label;
            }
            fields["cj6"] = this.vendortemplateid;
            fields["cj5"] = this.templatename;
            fields["cj4"] = this.aliasname;
            fields["cj7"] = this.smsmessage;
            if (!this.v$.$error && !this.duplicateTemplateMsg) {
                this.smsgbtnloader = true;
                this.ApiService.addSmsCampaignTemplate(fields).then((data) => {
                    if (data.status == 200) {
                        this.smsgbtnloader = false;
                        this.$store.dispatch("addCampaignStatus");
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.redirectcampaigntemplate();
                    } else {
                        this.smsgbtnloader = false;
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                    }
                });

            } else {
                this.$error;
            }
        },
        htmlconvertmsg() {
            var messagevalue = this.smsmessage;
            this.smsmessageinput = messagevalue.replace(/\n/g, '<br/>');
        },
        onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        redirectcampaigntemplate() {
            window.location.href = "/campaigntemplate/sms";
        },
        checkTemplateName() {
            if ((this.templatename).length > 3 && localStorage.getItem('sms_templates') !== null) {
                var templates = JSON.parse(localStorage.getItem('sms_templates'));
                for (var i = 0, len = templates.length; i < len; i++) {
                    if ((templates[i]['cg3']).toLowerCase() === (this.templatename).toLowerCase()) {
                        this.duplicateTemplateMsg = 'Template name already exits.';
                        break;
                    } else {
                        this.duplicateTemplateMsg = '';
                    }
                }
            }
        },
    },
};
</script>
<style scoped>
.campaign-modal-outer-border {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.intelligent-campagin-modal-header {
    font-family: 'AcuminPro-SemiBold';
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0B294B;
    line-height: 18px;
    margin-top: 10px;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.card-template {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 0px 15px;
}

.preview-card-template {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 14px 15px;
    height: auto;
    min-height: 197px;
}

.preview-body-msg {
    font-family: "AcuminPro-Regular";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 17px;
    margin-top: 15px;
}

.preview-footer-msg {
    margin-top: 15px;
}
</style>