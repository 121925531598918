<template>
    <div class="row">
        <div class="col-12">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="intelligent-intelligent-tab" data-bs-toggle="tab"
                        data-bs-target="#intelligentformdetail" type="button" role="tab" aria-controls="Intelligent-details"
                        aria-selected="true" @click="selectedtab((e = 1))">Intelligent
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="whatsapp-intelligent-tab" data-bs-toggle="tab"
                        data-bs-target="#whatsappformdetail" type="button" role="tab" aria-controls="Intelligent-details"
                        aria-selected="false" @click="selectedtab((e = 2))">
                        WhatsApp
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="imessage-intelligent-tab" data-bs-toggle="tab"
                        data-bs-target="#imessageformdetail" type="button" role="tab" aria-controls="Intelligent-details"
                        aria-selected="false" @click="selectedtab((e = 3))">
                        RCS / iMessage
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="sms-intelligent-tab" data-bs-toggle="tab" data-bs-target="#smsformdetail"
                        type="button" role="tab" aria-controls="Intelligent-details" aria-selected="false"
                        @click="selectedtab((e = 4))">
                        SMS
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="email-intelligent-tab" data-bs-toggle="tab"
                        data-bs-target="#emailformdetail" type="button" role="tab" aria-controls="Intelligent-details"
                        aria-selected="false" @click="selectedtab((e = 5))">
                        Email
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="audio-intelligent-tab" data-bs-toggle="tab"
                        data-bs-target="#audioformdetail" type="button" role="tab" aria-controls="Intelligent-details"
                        aria-selected="false" @click="selectedtab((e = 6))">
                        Audio Call
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="intelligentformdetail" role="tabpanel"
                    aria-labelledby="intelligent-intelligent-tab">
                    <div v-if="selected_tab == 1" class="campaign-modal-outer-border">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label class="intelligent-campagin-modal-header">Intelligent Campaign</label>
                                </div>
                            </div>
                        </div>
                        <div class="campaign-tab-outer-scroll" :style="{ height: '339px' }">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Template Name <span class="text-danger">*</span></label>
                                        <input type="text" v-model="int.templatename" class="form-control"
                                            placeholder="Enter Template Name" maxlength="250" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.int.templatename.$error">
                                            {{ v$.int.templatename.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label for="formmessageInput" class="form-label">Message <span
                                                class="text-danger">*</span></label>
                                        <textarea type="text" v-model="int.intelligentmessage" class="form-control"
                                            id="formmessageInput" placeholder="Enter Message" autocomplete="off"
                                            rows="3"></textarea>
                                        <div class="custom-error" v-if="v$.int.intelligentmessage.$error">
                                            {{ v$.int.intelligentmessage.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="custom-form-group text-center">
                                        <button type="button" class="btn modal-bulk-next-btn"
                                            @click="intelligentsubmitbtn()" :disabled="intbtnloader">
                                            <span v-if="!intbtnloader">Submit</span>
                                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                                v-if="intbtnloader">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="whatsappformdetail" role="tabpanel"
                    aria-labelledby="whatsapp-intelligent-tab">
                    <div v-if="selected_tab == 2" class="campaign-modal-outer-border">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label class="intelligent-campagin-modal-header">Intelligent WhatsApp Campaign</label>
                                </div>
                            </div>
                        </div>
                        <div class="campaign-tab-outer-scroll" :style="{ height: '339px' }">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Template Name <span class="text-danger">*</span></label>
                                        <input type="text" v-model="wht.templatename" class="form-control"
                                            placeholder="Enter Template Name" maxlength="512" autocomplete="off"
                                            v-on:keyup="showTemplateCountDown" />
                                        <div class="text-muted text-end" v-if="wht.templatename.length != 0">{{
                                            templatenameRemainCountTotal
                                        }}/512</div>
                                        <div class="custom-error" v-if="v$.wht.templatename.$error">
                                            {{ v$.wht.templatename.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Alias Name <span class="text-danger">*</span></label>
                                        <input type="text" v-model="wht.aliasname" class="form-control"
                                            placeholder="Enter Alias Name" maxlength="512" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.wht.aliasname.$error">
                                            {{ v$.wht.aliasname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Choose Category<span class="text-danger">*</span></label>
                                        <Multiselect v-model="wht.categorytype" :options="categorytypelist"
                                            :searchable="true" label="label" placeholder="Select Category"
                                            class="multiselect-custom text-capitalize" :canClear="false"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                                        <div class="custom-error" v-if="v$.wht.categorytype.$error">
                                            {{ v$.wht.categorytype.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Choose Language<span class="text-danger">*</span></label>
                                        <Multiselect v-model="wht.languagevalue" :options="languagelist" :searchable="true"
                                            label="label" placeholder="Select Language"
                                            class="multiselect-custom text-capitalize" :canClear="false"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                                        <div class="custom-error" v-if="v$.wht.languagevalue.$error">
                                            {{ v$.wht.languagevalue.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="wht.languagevalue != ''">
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="card-template">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Header<span
                                                                    class="text-danger">*</span></label>
                                                            <div class="custom-group-radio-box-btn">
                                                                <input type="radio" class="btn-check" @click="clearheader()"
                                                                    v-model="wht.headertype" name="none-option"
                                                                    id="headernone" autocomplete="off" value="0" />
                                                                <label class="btn custom-radio-box-btn text-capitalize"
                                                                    for="headernone">None</label>
                                                                <input type="radio" class="btn-check" @click="clearheader()"
                                                                    v-model="wht.headertype" name="text-option"
                                                                    id="headermedia" autocomplete="off" value="1" />
                                                                <label class="btn custom-radio-box-btn text-capitalize"
                                                                    for="headermedia">Media</label>
                                                            </div>
                                                            <div class="custom-error" v-if="v$.wht.headertype.$error">
                                                                {{ v$.wht.headertype.$errors[0].$message }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12" v-if="wht.headertype == 1">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Select Media Type<span
                                                                    class="text-danger">*</span></label>
                                                            <Multiselect v-model="wht.mediatype"
                                                                @change="mediatypechanges()" :options="mediatypelist"
                                                                :searchable="true" label="label" placeholder="Select Media"
                                                                class="multiselect-custom text-capitalize" :canClear="false"
                                                                :closeOnSelect="true" noOptionsText="No Result Found"
                                                                :object="true" />
                                                            <div class="custom-error" v-if="v$.wht.mediatype.$error">
                                                                {{ v$.wht.mediatype.$errors[0].$message }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12"
                                                        v-if="wht.headertype == 1 && wht.mediatype.value == 1">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Upload Image <span
                                                                    class="text-danger">*</span></label>
                                                            <FileUpload mode="basic" accept="image/*" :auto="true"
                                                                ref="whtheaderimage" class="custom-upload-btn-ultima"
                                                                v-model="wht.whtheaderimage"
                                                                v-on:change="handleWhtImageUploader()" />
                                                            <div class="custom-error" v-if="v$.wht.whtheaderimage.$error">
                                                                {{ v$.wht.whtheaderimage.$errors[0].$message }}
                                                            </div>
                                                            <div class="custom-error" v-if="wht.errorwhtheaderimage">{{
                                                                wht.errorwhtheaderimage }}</div>
                                                            <div class="text-primary mt-1"
                                                                v-if="wht.exitwhatheaderpreviewimgurl != null">{{
                                                                    wht.exitwhatheaderpreviewimgurl.name }}</div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-lg-4 col-md-4 col-12" v-if="exitwhatheaderpreviewimgurl != null">
                                            <img :src="exitwhatheaderpreviewimgurl.objectURL" height="72" class="
                                        rounded
                                        bg-light
                                        me-auto
                                        d-block
                                        " alt="" />
                                        </div> -->
                                                    <div class="col-lg-6 col-md-6 col-12"
                                                        v-if="wht.headertype == 1 && wht.mediatype.value == 2">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Upload Video<span
                                                                    class="text-danger">*</span></label>
                                                            <FileUpload mode="basic" :auto="true" ref="whtheadervideo"
                                                                class="custom-upload-btn-ultima"
                                                                v-model="wht.whtheadervideo"
                                                                v-on:change="handleWhtVideoUploader()" />
                                                            <div class="custom-error" v-if="v$.wht.whtheadervideo.$error">
                                                                {{ v$.wht.whtheadervideo.$errors[0].$message }}
                                                            </div>
                                                            <div class="custom-error" v-if="wht.errorwhtheadervideo">{{
                                                                wht.errorwhtheadervideo }}</div>
                                                            <div class="text-primary mt-1"
                                                                v-if="wht.exitwhatheaderpreviewvideourl != null">{{
                                                                    wht.exitwhatheaderpreviewvideourl.name }}</div>
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-12"
                                                        v-if="wht.headertype == 1 && wht.mediatype.value == 3">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Upload Document<span
                                                                    class="text-danger">*</span></label>
                                                            <FileUpload mode="basic" :auto="true" ref="whtheaderdoc"
                                                                class="custom-upload-btn-ultima" v-model="wht.whtheaderdoc"
                                                                v-on:change="handleWhtDocUploader()" />
                                                            <div class="custom-error" v-if="v$.wht.whtheaderdoc.$error">
                                                                {{ v$.wht.whtheaderdoc.$errors[0].$message }}
                                                            </div>
                                                            <div class="custom-error" v-if="wht.errorwhtheaderdoc">{{
                                                                wht.errorwhtheaderdoc }}</div>
                                                            <div class="text-primary mt-1"
                                                                v-if="wht.exitwhatheaderpreviewdovurl != null">{{
                                                                    wht.exitwhatheaderpreviewdovurl.name }}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-template mt-3">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="custom-form-group">
                                                            <label for="formmessageInput" class="form-label">Message <span
                                                                    class="text-danger">*</span></label>
                                                            <textarea type="text" id="formmessageInput"
                                                                v-model="wht.whatsappmessage"
                                                                class="form-control custom-text-area-scroll"
                                                                placeholder="Enter Message" v-on:keyup="htmlconvertmsg()"
                                                                autocomplete="off" rows="3" maxlength="1024"
                                                                :style="{ resize: 'vertical' }"></textarea>
                                                            <!-- <div class="text-muted text-end"
                                                                v-if="wht.whatsappmessage.length != 0">{{
                                                                    templatebodyRemainCountTotal
                                                                }}/512</div> -->
                                                            <div class="custom-error" v-if="v$.wht.whatsappmessage.$error">
                                                                {{ v$.wht.whatsappmessage.$errors[0].$message }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-template mt-3">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Footer</label>
                                                            <input type="text" v-model="wht.footername" class="form-control"
                                                                placeholder="Enter Footer" maxlength="60"
                                                                autocomplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-template mt-3">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-12">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Buttons</label>
                                                            <Multiselect v-model="wht.buttontype"
                                                                @change="buttontypechanges()" :options="buttontypelist"
                                                                :searchable="false" label="label"
                                                                placeholder="Select Button"
                                                                class="multiselect-custom text-capitalize" :canClear="false"
                                                                :closeOnSelect="true" noOptionsText="No Result Found"
                                                                :object="true" />
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12"
                                                        v-if="wht.buttontype.value == 1">
                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-4 col-12">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12 col-12">
                                                                        <div class="custom-form-group">
                                                                            <label class="form-label">First Button Name<span class="text-danger">*</span></label>
                                                                            <input type="text" v-model="wht.firstbtnname"
                                                                                class="form-control" placeholder="Enter Button Name"
                                                                                maxlength="30" autocomplete="off" />
                                                                            <div class="custom-error"
                                                                                v-if="v$.wht.firstbtnname.$error">
                                                                                {{ v$.wht.firstbtnname.$errors[0].$message }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-md-12 col-12" v-if="wht.firstbtnname != ''">
                                                                        <div class="custom-form-group">
                                                                            <label class="form-label">Select Response<span class="text-danger">*</span></label>
                                                                            <Multiselect v-model="wht.firstbtnresponse" :options="responselist" :searchable="true" label="label"
                                                                                placeholder="Select..." class="multiselect-custom text-capitalize" :canClear="false"
                                                                                :closeOnSelect="true" noOptionsText="No Result Found" :object="false" />
                                                                            <div class="custom-error" v-if="v$.wht.firstbtnresponse.$error">
                                                                                {{ v$.wht.firstbtnresponse.$errors[0].$message }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-12">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12 col-12">
                                                                        <div class="custom-form-group">
                                                                            <label class="form-label">Second Button Name</label>
                                                                            <input type="text" v-model="wht.secondbtnname"
                                                                        class="form-control" placeholder="Enter Button Name"
                                                                        maxlength="30" autocomplete="off" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-md-12 col-12" v-if="wht.secondbtnname != ''">
                                                                        <div class="custom-form-group">
                                                                            <label class="form-label">Select Response<span class="text-danger">*</span></label>
                                                                            <Multiselect v-model="wht.secondbtnresponse" :options="responselist" :searchable="true" label="label"
                                                                                placeholder="Select..." class="multiselect-custom text-capitalize" :canClear="false"
                                                                                :closeOnSelect="true" noOptionsText="No Result Found" :object="false" />
                                                                            <div class="custom-error" v-if="v$.wht.secondbtnresponse.$error">
                                                                                {{ v$.wht.secondbtnresponse.$errors[0].$message }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4 col-12">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12 col-12">
                                                                        <div class="custom-form-group">
                                                                            <label class="form-label">Third Button Name</label>
                                                                            <input type="text" v-model="wht.thirdbtnname"
                                                                        class="form-control" placeholder="Enter Button Name"
                                                                        maxlength="30" autocomplete="off" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-md-12 col-12" v-if="wht.thirdbtnname != ''">
                                                                        <div class="custom-form-group">
                                                                            <label class="form-label">Select Response<span class="text-danger">*</span></label>
                                                                            <Multiselect v-model="wht.thirdbtnresponse" :options="responselist" :searchable="true" label="label"
                                                                                placeholder="Select..." class="multiselect-custom text-capitalize" :canClear="false"
                                                                                :closeOnSelect="true" noOptionsText="No Result Found" :object="false" />
                                                                            <div class="custom-error" v-if="v$.wht.thirdbtnresponse.$error">
                                                                                {{ v$.wht.thirdbtnresponse.$errors[0].$message }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12"
                                                        v-if="wht.buttontype.value == 2">
                                                        <div class="row">
                                                            <div class="col-lg-6 col-md-6 col-12">
                                                                <div class="custom-form-group">
                                                                    <label class="form-label">Call Button Text</label>
                                                                    <input type="text" v-model="wht.callbtntext"
                                                                        class="form-control" placeholder="Enter Button Text"
                                                                        maxlength="30" autocomplete="off" />
                                                                    <div class="custom-error"
                                                                        v-if="v$.wht.callbtntext.$error">
                                                                        {{ v$.wht.callbtntext.$errors[0].$message }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6 col-12">
                                                                <div class="custom-form-group">
                                                                    <label class="form-label">Mobile No.</label>
                                                                    <div class="input-group custom-input-group">
                                                                        <div class="col-lg-4 col-md-4 col-12">
                                                                            <Multiselect v-model="wht.countrycode"
                                                                                :options="countryCodeList"
                                                                                :searchable="true" label="label"
                                                                                placeholder="Select" disabled
                                                                                class="multiselect-custom custom-title-multiselect"
                                                                                :canClear="false" :closeOnSelect="true"
                                                                                :object="true" noOptionsText="No Result"
                                                                                :canDeselect="false" />
                                                                        </div>
                                                                        <div class="col-lg-8 col-md-8 col-12">
                                                                            <input type="text" v-model="wht.callmobileno"
                                                                                class="form-control"
                                                                                placeholder="Enter Mobile No."
                                                                                maxlength="10" id="formMobileNoInput"
                                                                                @keypress="onlyNumberMobile"
                                                                                autocomplete="off" />
                                                                        </div>
                                                                        <div class="custom-error"
                                                                            v-if="v$.wht.callmobileno.$error">
                                                                            {{ v$.wht.callmobileno.$errors[0].$message }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <divider class="mb-3" />
                                                    <div class="col-lg-12 col-md-12 col-12"
                                                        v-if="wht.buttontype.value == 2">
                                                        <div class="row">
                                                            <div class="col-lg-6 col-md-6 col-12">
                                                                <div class="custom-form-group">
                                                                    <label class="form-label">URL Button Text</label>
                                                                    <input type="text" v-model="wht.urlbtntext"
                                                                        class="form-control" placeholder="Enter Button Text"
                                                                        maxlength="30" autocomplete="off" />
                                                                    <div class="custom-error"
                                                                        v-if="v$.wht.urlbtntext.$error">
                                                                        {{ v$.wht.urlbtntext.$errors[0].$message }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-md-12 col-12">
                                                                <div class="custom-form-group">
                                                                    <label class="form-label">Website URL</label>
                                                                    <input type="text" v-model="wht.websiteurllink"
                                                                        class="form-control" placeholder="Enter Website URL"
                                                                        maxlength="30" autocomplete="off" />
                                                                    <div class="custom-error"
                                                                        v-if="wht.websiteurllinkErr || wht.websiteurllink != ''">
                                                                        {{ wht.websiteurllinkErr }}
                                                                    </div>
                                                                    <div class="custom-error"
                                                                        v-if="v$.wht.websiteurllink.$error">
                                                                        {{ v$.wht.websiteurllink.$errors[0].$message }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12">
                                                    <div class="custom-form-group text-center">
                                                        <button type="button" class="btn modal-bulk-next-btn"
                                                            @click="whatsappsubmitbtn()" :disabled="whtbtnloader">
                                                            <span v-if="!whtbtnloader">Submit</span>
                                                            <div class="spinner-border text-light custom-spinner-loader-btn"
                                                                role="status" v-if="whtbtnloader">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="intelligent-campagin-modal-header mb-2 text-center">Message Preview
                                            </div>
                                        </div>
                                    </div>
                                    <div class="preview-card-template">
                                        <div v-if="wht.headertype == 1 && wht.mediatype.value != ''">
                                            <img v-if="wht.whtheaderimage != null"
                                                :src="wht.exitwhatheaderpreviewimgurl.objectURL" :style="{ width: '100%' }"
                                                alt="" />
                                            <img v-if="wht.mediatype.value == 1 && wht.whtheaderimage == null"
                                                src="/assets/images/preview-img.jpg" alt="" :style="{ width: '100%' }" />
                                            <video id="video-preview" controls :style="{ width: '235px' }"
                                                v-show="this.wht.whtheadervideo != null" />
                                            <img v-if="wht.mediatype.value == 2 && this.wht.whtheadervideo == null"
                                                src="/assets/images/preview-clip.jpg" alt="" :style="{ width: '100%' }" />
                                            <img v-if="wht.mediatype.value == 3" src="/assets/images/preview-doc.jpg" alt=""
                                                :style="{ width: '100%' }" />
                                        </div>
                                        <div class="preview-body-msg" v-if="wht.whatsappmessage != ''">
                                            <p v-html="this.whatsappmessageinput"></p>
                                        </div>
                                        <div class="preview-footer-msg" v-if="wht.footername">
                                            {{ this.wht.footername }}
                                        </div>
                                        <Divider v-if="wht.firstbtnname || wht.secondbtnname || wht.thirdbtnname"
                                            type="dashed" />
                                        <button v-if="wht.firstbtnname" type="button"
                                            class="btn modal-bulk-next-btn w-100 my-1 text-capitalize"><i
                                                class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
                                                    this.wht.firstbtnname }}</button>
                                        <button v-if="wht.secondbtnname" type="button"
                                            class="btn modal-bulk-next-btn w-100 my-1 text-capitalize"><i
                                                class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
                                                    this.wht.secondbtnname }}</button>
                                        <button v-if="wht.thirdbtnname" type="button"
                                            class="btn modal-bulk-next-btn w-100 my-1 text-capitalize"><i
                                                class="pi pi-reply me-2" style="font-size: 0.7rem;"></i>{{
                                                    this.wht.thirdbtnname }}</button>
                                        <button v-if="wht.callbtntext" type="button"
                                            class="btn modal-bulk-next-btn w-100 my-1 text-capitalize"><i
                                                class="pi pi-phone me-2" style="font-size: 0.7rem;"></i>{{
                                                    this.wht.callbtntext }}</button>
                                        <button v-if="wht.urlbtntext" type="button"
                                            class="btn modal-bulk-next-btn w-100 my-1 text-capitalize"><i
                                                class="pi pi-external-link me-2" style="font-size: 0.7rem;"></i>{{
                                                    this.wht.urlbtntext }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="imessageformdetail" role="tabpanel"
                    aria-labelledby="imessage-intelligent-tab">
                    <div v-if="selected_tab == 3" class="campaign-modal-outer-border">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label class="intelligent-campagin-modal-header">Intelligent RCS / iMessage
                                        Campaign</label>
                                </div>
                            </div>
                        </div>
                        <div class="campaign-tab-outer-scroll" :style="{ height: '339px' }">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Template Name <span class="text-danger">*</span></label>
                                        <input type="text" v-model="imsg.templatename" class="form-control"
                                            placeholder="Enter Template Name" maxlength="250" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.imsg.templatename.$error">
                                            {{ v$.imsg.templatename.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Header Image <span
                                                class="text-danger">*</span></label>
                                        <FileUpload mode="basic" accept=".csv" ref="importvoterfile"
                                            class="custom-upload-btn-ultima" v-model="imsg.whtheaderimage"
                                            v-on:change="handleimsgImageUploader()" />
                                        <div class="custom-error" v-if="v$.imsg.whtheaderimage.$error">
                                            {{ v$.imsg.whtheaderimage.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Header Video<span
                                                class="text-danger">*</span></label>
                                        <FileUpload mode="basic" accept=".csv" ref="importvoterfile"
                                            class="custom-upload-btn-ultima" v-model="imsg.whtheadervideo"
                                            v-on:change="handleimsgVideoUploader()" />
                                        <div class="custom-error" v-if="v$.imsg.whtheadervideo.$error">
                                            {{ v$.imsg.whtheadervideo.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label for="formmessageInput" class="form-label">Message <span
                                                class="text-danger">*</span></label>
                                        <textarea type="text" v-model="imsg.imessage" class="form-control"
                                            id="formmessageInput" placeholder="Enter Message" autocomplete="off"
                                            rows="3"></textarea>
                                        <div class="custom-error" v-if="v$.imsg.imessage.$error">
                                            {{ v$.imsg.imessage.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">First Button Name <span
                                                class="text-danger">*</span></label>
                                        <input type="text" v-model="imsg.firstbtnname" class="form-control"
                                            placeholder="Enter First Button Name" maxlength="30" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.imsg.firstbtnname.$error">
                                            {{ v$.imsg.firstbtnname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Second Button Name <span
                                                class="text-danger">*</span></label>
                                        <input type="text" v-model="imsg.secondbtnname" class="form-control"
                                            placeholder="Enter Second Button Name" maxlength="30" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.imsg.secondbtnname.$error">
                                            {{ v$.imsg.secondbtnname.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Third Button Name</label>
                                        <input type="text" v-model="imsg.thirdbtnname" class="form-control"
                                            placeholder="Enter Third Button Name" maxlength="30" autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="custom-form-group text-center">
                                        <button type="button" class="btn modal-bulk-next-btn" @click="imessagesubmitbtn()"
                                            :disabled="imsgbtnloader">
                                            <span v-if="!imsgbtnloader">Submit</span>
                                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                                v-if="imsgbtnloader">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="smsformdetail" role="tabpanel" aria-labelledby="SMS-intelligent-tab">
                    <div v-if="selected_tab == 4" class="campaign-modal-outer-border">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label class="intelligent-campagin-modal-header">Intelligent SMS Campaign</label>
                                </div>
                            </div>
                        </div>
                        <div class="campaign-tab-outer-scroll" :style="{ height: '339px' }">
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Sender ID / Header<span class="text-danger">*</span></label>
                                        <Multiselect v-model="sms.senderid" :options="senderidlist" :searchable="true" label="label"
                                            placeholder="Select Sender ID / Header" class="multiselect-custom text-capitalize" :canClear="false"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                                        <div class="custom-error" v-if="v$.sms.senderid.$error">
                                            {{ v$.sms.senderid.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Vendor Template ID<span
                                                class="text-danger">*</span></label>
                                        <input type="text" v-model="sms.vendortemplateid" class="form-control"
                                            placeholder="Enter Vendor Template ID" @paste="onPasteMobile" @keypress="onlyNumber"
                                            autocomplete="off" />
                                        <div class="custom-error" v-if="v$.sms.vendortemplateid.$error">
                                            {{ v$.sms.vendortemplateid.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-8 col-md-8 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="card-template mt-3">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Template Name <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="sms.templatename" v-on:keyup="checkTemplateName()" class="form-control" placeholder="Enter Template Name"
                                                                maxlength="250" autocomplete="off" />
                                                            <div class="custom-error" v-if="v$.sms.templatename.$error">
                                                                {{ v$.sms.templatename.$errors[0].$message }}
                                                            </div>
                                                            <div class="custom-error" v-if="duplicateTemplateMsg">
                                                                {{ duplicateTemplateMsg }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="card-template mt-3">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="custom-form-group">
                                                            <label class="form-label">Alias Name <span class="text-danger">*</span></label>
                                                            <input type="text" v-model="sms.aliasname" class="form-control" placeholder="Enter Alias Name"
                                                                maxlength="250" autocomplete="off" />
                                                            <div class="custom-error" v-if="v$.sms.aliasname.$error">
                                                                {{ v$.sms.aliasname.$errors[0].$message }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-md-12 col-12">
                                                        <div class="custom-form-group">
                                                            <label for="formmessageInput" class="form-label">Message <span class="text-danger">*</span></label>
                                                            <textarea type="text" v-model="sms.smsmessage" class="form-control" id="formmessageInput"
                                                                placeholder="Enter Message" v-on:keyup="htmlsmsconvertmsg()" autocomplete="off" rows="3" maxlength="1024"
                                            :style="{ resize: 'vertical' }"></textarea>
                                                            <div class="custom-error" v-if="v$.sms.smsmessage.$error">
                                                                {{ v$.sms.smsmessage.$errors[0].$message }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col-12">
                                                    <div class="custom-form-group text-center">
                                                        <button type="button" class="btn modal-bulk-next-btn" @click="smsSubmitBtn()" :disabled="smsgbtnloader">
                                                            <span v-if="!smsgbtnloader">Submit</span>
                                                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="smsgbtnloader">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="intelligent-campagin-modal-header mb-2 text-center">Message Preview</div>
                                        </div>
                                    </div>
                                    <div class="preview-card-template">
                                        <div class="preview-body-msg" v-if="sms.smsmessage != ''">
                                            <p v-html="this.sms.smsmessageinput"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="emailformdetail" role="tabpanel" aria-labelledby="email-intelligent-tab">
                    <div v-if="selected_tab == 5" class="campaign-modal-outer-border">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label class="intelligent-campagin-modal-header">Intelligent Email Campaign</label>
                                </div>
                            </div>
                        </div>
                        <div class="campaign-tab-outer-scroll" :style="{ height: '339px' }">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Template Name <span class="text-danger">*</span></label>
                                        <input type="text" v-model="email.templatename" class="form-control"
                                            placeholder="Enter Template Name" maxlength="250" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.email.templatename.$error">
                                            {{ v$.email.templatename.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label for="formmessageInput" class="form-label">Message <span
                                                class="text-danger">*</span></label>
                                        <textarea type="text" v-model="email.emailmessage" class="form-control"
                                            id="formmessageInput" placeholder="Enter Message" autocomplete="off"
                                            rows="3"></textarea>
                                        <div class="custom-error" v-if="v$.email.emailmessage.$error">
                                            {{ v$.email.emailmessage.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="custom-form-group text-center">
                                        <button type="button" class="btn modal-bulk-next-btn" @click="emailsubmitbtn()"
                                            :disabled="emailgbtnloader">
                                            <span v-if="!emailgbtnloader">Submit</span>
                                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                                v-if="emailgbtnloader">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="audioformdetail" role="tabpanel" aria-labelledby="audio-intelligent-tab">
                    <div v-if="selected_tab == 6" class="campaign-modal-outer-border">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label class="intelligent-campagin-modal-header">Intelligent Audio Call Campaign</label>
                                </div>
                            </div>
                        </div>
                        <div class="campaign-tab-outer-scroll" :style="{ height: '339px' }">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Template Name <span class="text-danger">*</span></label>
                                        <input type="text" v-model="audio.templatename" v-on:keyup="checkSmsTemplateName()" class="form-control" placeholder="Enter Template Name"
                                            maxlength="250" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.audio.templatename.$error">
                                            {{ v$.audio.templatename.$errors[0].$message }}
                                        </div>
                                        <div class="custom-error" v-if="duplicateTemplateAudioMsg">
                                            {{ duplicateTemplateAudioMsg }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Language<span class="text-danger">*</span></label>
                                        <Multiselect v-model="audio.languagevalue" :options="languagelist" :searchable="true" label="label"
                                            placeholder="Select Language" class="multiselect-custom text-capitalize" :canClear="false"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                                        <div class="custom-error" v-if="v$.audio.languagevalue.$error">
                                            {{ v$.audio.languagevalue.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Response Type<span class="text-danger">*</span></label>
                                        <div class="custom-group-radio-box-btn">
                                            <input type="radio" class="btn-check" v-model="audio.responsetype" name="none-option" id="noresponse"
                                                autocomplete="off" @click="clearresponse()" value="0" />
                                            <label class="btn custom-radio-box-btn text-capitalize" for="noresponse">No Response</label>
                                            <input type="radio" class="btn-check" v-model="audio.responsetype" name="with-option" id="withresponse"
                                                autocomplete="off" value="1" />
                                            <label class="btn custom-radio-box-btn text-capitalize" for="withresponse">With Response</label>
                                        </div>
                                        <div class="custom-error" v-if="v$.audio.responsetype.$error">
                                            {{ v$.audio.responsetype.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Audio<span class="text-danger">*</span></label>
                                        <FileUpload mode="basic" accept=".mp3" ref="headeraudioref" class="custom-upload-btn-ultima"
                                            v-model="audio.headeraudio" v-on:change="handlerAudioUploader()" />
                                        <div class="custom-error" v-if="errortuneaddfile">{{ errortuneaddfile }}</div>
                                        <div class="custom-error" v-if="v$.audio.headeraudio.$error">
                                            {{ v$.audio.headeraudio.$errors[0].$message }}
                                        </div>
                                    </div>
                                    <!-- <div v-if="exitaddpreviewurl != null">
                                        <audio controls controlsList="download" style="width: 40%;">
                                            <source :src="exitaddpreviewurl.objectURL" type="audio/mpeg" />
                                        </audio>
                                    </div> -->
                                </div>
                            </div>
                            <!--  -->
                            <div class="row" v-if="audio.responsetype == 1">
                                <div class="col-lg-12 col-md-12 col-12 mb-3">
                                    <div class="form-box-outer">
                                        <!-- Response One  -->
                                        <div class="custom-form-group">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="audio.responsenumberone" id="responsenumberone"
                                                            class="form-control text-center fw-bold" disabled maxlength="1"
                                                            @keypress="onlyResponseOneFormat" autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                            <input type="text" v-model="audio.responsevalueone" class="form-control"
                                                                placeholder="Enter Response Value" maxlength="30"
                                                                @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.audio.responsevalueone.$error">
                                                        {{ v$.audio.responsevalueone.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="audio.responsestatusone" :true-value="dynamicTrueActiveValue"
                                                        :false-value="dynamicFalseInactiveValue" class="mt-1"
                                                        @change="changeStatusOne(audio.responsestatusone)" />
                                                    <div class="custom-error" v-if="v$.audio.responsestatusone.$error">
                                                        {{ v$.audio.responsestatusone.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" />
                                        <!-- Response Two  -->
                                        <div class="custom-form-group">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="audio.responsenumbertwo" id="responsenumbertwo"
                                                            class="form-control text-center fw-bold" disabled maxlength="1"
                                                            @keypress="onlyResponseTwoFormat" autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                            <input type="text" v-model="audio.responsevaluetwo" class="form-control"
                                                                placeholder="Enter Response Value" maxlength="30"
                                                                @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.audio.responsevaluetwo.$error">
                                                        {{ v$.audio.responsevaluetwo.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="audio.responsestatustwo" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.audio.responsestatustwo.$error">
                                                        {{ v$.audio.responsestatustwo.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="thirdrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreThirdRow()" class="btn custom-view-detail-btn"><i
                                                            class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="thirdrowshowstatus" />
                                        <!-- Response Three  -->
                                        <div class="custom-form-group" v-if="thirdrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="audio.responsenumberthree" id="responsenumberthree"
                                                            class="form-control text-center fw-bold" disabled maxlength="1"
                                                            @keypress="onlyResponseThreeFormat" autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                            <input type="text" v-model="audio.responsevaluethree" class="form-control"
                                                                placeholder="Enter Response Value" maxlength="30"
                                                                @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.audio.responsevaluethree.$error">
                                                        {{ v$.audio.responsevaluethree.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="audio.responsestatusthree" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.audio.responsestatusthree.$error">
                                                        {{ v$.audio.responsestatusthree.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="fourthrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreFourthRow()" class="btn custom-view-detail-btn"><i
                                                            class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="fourthrowshowstatus" />
                                        <!-- Response Four  -->
                                        <div class="custom-form-group" v-if="fourthrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="audio.responsenumberfour" id="responsenumberfour"
                                                            class="form-control text-center fw-bold" disabled maxlength="1"
                                                            @keypress="onlyResponseFourFormat" autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                            <input type="text" v-model="audio.responsevaluefour" class="form-control"
                                                                placeholder="Enter Response Value" maxlength="30"
                                                                @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.audio.responsevaluefour.$error">
                                                        {{ v$.audio.responsevaluefour.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="audio.responsestatusfour" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.audio.responsestatusfour.$error">
                                                        {{ v$.audio.responsestatusfour.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="fifthrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreFifthRow()" class="btn custom-view-detail-btn"><i
                                                            class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="fifthrowshowstatus" />
                                        <!-- Response Five  -->
                                        <div class="custom-form-group" v-if="fifthrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="audio.responsenumberfive" id="responsenumberfive"
                                                            class="form-control text-center fw-bold" disabled maxlength="1"
                                                            @keypress="onlyResponseFiveFormat" autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                            <input type="text" v-model="audio.responsevaluefive" class="form-control"
                                                                placeholder="Enter Response Value" maxlength="30"
                                                                @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.audio.responsevaluefive.$error">
                                                        {{ v$.audio.responsevaluefive.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="audio.responsestatusfive" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.audio.responsestatusfive.$error">
                                                        {{ v$.audio.responsestatusfive.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="sixthrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreSixthRow()" class="btn custom-view-detail-btn"><i
                                                            class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="sixthrowshowstatus" />
                                        <!-- Response Six  -->
                                        <div class="custom-form-group" v-if="sixthrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="audio.responsenumbersix" id="responsenumbersix"
                                                            class="form-control text-center fw-bold" disabled maxlength="1"
                                                            @keypress="onlyResponseSixFormat" autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                            <input type="text" v-model="audio.responsevaluesix" class="form-control"
                                                                placeholder="Enter Response Value" maxlength="30"
                                                                @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.audio.responsevaluesix.$error">
                                                        {{ v$.audio.responsevaluesix.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="audio.responsestatussix" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.audio.responsestatussix.$error">
                                                        {{ v$.audio.responsestatussix.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="seventhrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreSeventhRow()" class="btn custom-view-detail-btn"><i
                                                            class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="seventhrowshowstatus" />
                                        <!-- Response Seven  -->
                                        <div class="custom-form-group" v-if="seventhrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="audio.responsenumberseven" id="responsenumberseven"
                                                            class="form-control text-center fw-bold" disabled maxlength="1"
                                                            @keypress="onlyResponseSevenFormat" autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                            <input type="text" v-model="audio.responsevalueseven" class="form-control"
                                                                placeholder="Enter Response Value" maxlength="30"
                                                                @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.audio.responsevalueseven.$error">
                                                        {{ v$.audio.responsevalueseven.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="audio.responsestatusseven" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.audio.responsestatusseven.$error">
                                                        {{ v$.audio.responsestatusseven.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="eighthrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreEighthRow()" class="btn custom-view-detail-btn"><i
                                                            class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="eighthrowshowstatus" />
                                        <!-- Response Eight  -->
                                        <div class="custom-form-group" v-if="eighthrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="audio.responsenumbereight" id="responsenumbereight"
                                                            class="form-control text-center fw-bold" disabled maxlength="1"
                                                            @keypress="onlyResponseEightFormat" autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                            <input type="text" v-model="audio.responsevalueeight" class="form-control"
                                                                placeholder="Enter Response Value" maxlength="30"
                                                                @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.audio.responsevalueeight.$error">
                                                        {{ v$.audio.responsevalueeight.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="audio.responsestatuseight" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.audio.responsestatuseight.$error">
                                                        {{ v$.audio.responsestatuseight.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-form-group" v-if="ninethrowbtnstatus">
                                            <div class="row text-start">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <button type="button" @click="addMoreNinethRow()" class="btn custom-view-detail-btn"><i
                                                            class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider class="mb-3" v-if="ninethrowshowstatus" />
                                        <!-- Response Nine  -->
                                        <div class="custom-form-group" v-if="ninethrowshowstatus">
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-12">
                                                    <div class="input-group">
                                                        <input type="text" v-model="audio.responsenumbernine" id="responsenumbernine"
                                                            class="form-control text-center fw-bold" disabled maxlength="1"
                                                            @keypress="onlyResponseNineFormat" autocomplete="off" style="max-width: 12%;" />
                                                        <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                            <input type="text" v-model="audio.responsevaluenine" class="form-control"
                                                                placeholder="Enter Response Value" maxlength="30"
                                                                @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                        </span>
                                                    </div>
                                                    <div class="custom-error" v-if="v$.audio.responsevaluenine.$error">
                                                        {{ v$.audio.responsevaluenine.$errors[0].$message }}
                                                    </div>
                                                </div>
                                                <div class="col-lg-3 col-md-3 col-12">
                                                    <InputSwitch v-model="audio.responsestatusnine" class="mt-1" />
                                                    <div class="custom-error" v-if="v$.audio.responsestatusnine.$error">
                                                        {{ v$.audio.responsestatusnine.$errors[0].$message }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="custom-form-group text-center">
                                        <button type="button" class="btn modal-bulk-next-btn" @click="audiosubmitbtn()"
                                            :disabled="audiogbtnloader">
                                            <span v-if="!audiogbtnloader">Submit</span>
                                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                                v-if="audiogbtnloader">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, requiredIf, minLength, helpers } from "@vuelidate/validators";
export default {
    data() {
        return {
            v$: useValidate(),
            selected_tab: 1,
            intbtnloader: false,
            whtbtnloader: false,
            imsgbtnloader: false,
            smsgbtnloader: false,
            emailgbtnloader: false,
            audiogbtnloader: false,
            int: {
                templatename: '',
                intelligentmessage: '',
            },
            wht: {
                websiteurllinkErr: '',
                templatename: '',
                aliasname: '',
                categorytype: '',
                languagevalue: '',
                headertype: '',
                mediatype: '',
                whtheaderimage: null,
                errorwhtheaderimage: '',
                exitwhatheaderpreviewimgurl: null,

                whtheadervideo: null,
                errorwhtheadervideo: '',
                exitwhatheaderpreviewvideourl: null,

                whtheaderdoc: null,
                errorwhtheaderdoc: '',
                exitwhatheaderpreviewdovurl: null,

                whatsappmessage: '',
                footername: '',
                buttontype: '',
                firstbtnname: '',
                firstbtnresponse: '',
                secondbtnname: '',
                secondbtnresponse: '',
                thirdbtnname: '',
                thirdbtnresponse: '',
                responselist: [],
                countrycode: { value: 101, label: "+91" },
                callbtntext: '',
                callmobileno: '',
                urlbtntext: '',
                websiteurllink: '',
            },
            errmsg: '',
            categorytypelist: [
                // { label: "Authentication", value: 1, },
                { label: "Marketing", value: 2 },
                { label: "Utility", value: 3 },
            ],
            languagelist: [],
            mediatypelist: [
                { label: "Image", value: 1 },
                { label: "Video", value: 2 },
                { label: "Document", value: 3 },
            ],
            templatenameMxLimitCount: 250,
            templatenameRemainCountTotal: 250,
            templatebodyMxLimitCount: 250,
            templatebodyRemainCountTotal: 250,
            buttontypelist: [
                { label: "Select", value: '' },
                { label: "Quick Reply", value: 1 },
                { label: "Call To Action", value: 2 },
            ],
            countryCodeList: [],
            errStatus: false,
            imsg: {
                templatename: '',
                whtheaderimage: null,
                whtheadervideo: null,
                imessage: '',
                firstbtnname: '',
                secondbtnname: '',
                thirdbtnname: '',
            },
            senderidlist: [],
            duplicateTemplateMsg: '',
            sms: {
                senderid: '',
                vendortemplateid: '',
                templatename: '',
                aliasname: '',
                smsmessage: '',
                smsmessageinput: '',
            },
            email: {
                templatename: '',
                emailmessage: '',
            },
            audio: {
                templatename: '',
                languagevalue: '',
                responsetype: '',
                headeraudio: null,
                responsenumberone: 1,
                responsevalueone: '',
                responsestatusone: '',

                responsenumbertwo: 2,
                responsevaluetwo: '',
                responsestatustwo: '',

                responsenumberthree: 3,
                responsevaluethree: '',
                responsestatusthree: '',

                responsenumberfour: 4,
                responsevaluefour: '',
                responsestatusfour: '',

                responsenumberfive: 5,
                responsevaluefive: '',
                responsestatusfive: '',

                responsenumbersix: 6,
                responsevaluesix: '',
                responsestatussix: '',

                responsenumberseven: 7,
                responsevalueseven: '',
                responsestatusseven: '',

                responsenumbereight: 8,
                responsevalueeight: '',
                responsestatuseight: '',

                responsenumbernine: 9,
                responsevaluenine: '',
                responsestatusnine: '',

            },
            whatsappmessageinput: '',
            switchuncheck: false,
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,

            thirdrowshowstatus: false,
            thirdrowbtnstatus: true,

            fourthrowshowstatus: false,
            fourthrowbtnstatus: false,

            fifthrowshowstatus: false,
            fifthrowbtnstatus: false,

            sixthrowshowstatus: false,
            sixthrowbtnstatus: false,

            seventhrowshowstatus: false,
            seventhrowbtnstatus: false,

            eighthrowshowstatus: false,
            eighthrowbtnstatus: false,

            ninethrowshowstatus: false,
            ninethrowbtnstatus: false,
            duplicateTemplateAudioMsg: '',
            errortuneaddfile: '',
        };
    },
    validations() {
        return {
            int: {
                templatename: {
                    required: helpers.withMessage("Please enter template name", required),
                },
                intelligentmessage: {
                    required: helpers.withMessage("Please enter message", required),
                },
            },
            wht: {
                templatename: {
                    required: helpers.withMessage("Please enter template name", required),
                },
                aliasname: {
                    required: helpers.withMessage("Please enter alias name", required),
                },
                categorytype: {
                    required: helpers.withMessage("Please select category type", required),
                },
                languagevalue: {
                    required: helpers.withMessage("Please select language", required),
                },
                headertype: {
                    required: helpers.withMessage("Please select header", required),
                },
                mediatype: {
                    requiredIf: helpers.withMessage(
                        "Please select media type",
                        requiredIf(
                            this.wht.headertype == 1
                        )
                    ),
                },
                whtheaderimage: {
                    requiredIf: helpers.withMessage(
                        "Please upload image",
                        requiredIf(
                            this.wht.headertype == 1 && this.wht.mediatype.value == 1
                        )
                    ),
                },
                whtheadervideo: {
                    requiredIf: helpers.withMessage(
                        "Please upload video",
                        requiredIf(
                            this.wht.headertype == 1 && this.wht.mediatype.value == 2
                        )
                    ),
                },
                whtheaderdoc: {
                    requiredIf: helpers.withMessage(
                        "Please upload document",
                        requiredIf(
                            this.wht.headertype == 1 && this.wht.mediatype.value == 3
                        )
                    ),
                },
                whatsappmessage: {
                    required: helpers.withMessage("Please enter message", required),
                },
                firstbtnname: {
                    requiredIf: helpers.withMessage(
                        "Please enter button name",
                        requiredIf(
                            this.wht.buttontype.value == 1
                        )
                    ),
                },
                firstbtnresponse: {
                    requiredIf: helpers.withMessage(
                        "Please select response",
                        requiredIf(
                            this.wht.firstbtnname != '' && this.wht.buttontype.value == 1
                        )
                    ),
                },
                secondbtnresponse: {
                    requiredIf: helpers.withMessage(
                        "Please select response",
                        requiredIf(
                            this.wht.secondbtnname != '' && this.wht.buttontype.value == 1
                        )
                    ),
                },
                thirdbtnresponse: {
                    requiredIf: helpers.withMessage(
                        "Please select response",
                        requiredIf(
                            this.wht.thirdbtnname != '' && this.wht.buttontype.value == 1
                        )
                    ),
                },
                callbtntext: {
                    requiredIf: helpers.withMessage(
                        "Please enter button text",
                        requiredIf(
                            this.wht.callmobileno != ''
                        )
                    ),
                },
                callmobileno: {
                    requiredIf: helpers.withMessage(
                        "Please enter mobile No.",
                        requiredIf(
                            this.wht.callbtntext != ''
                        )
                    ), minLength: helpers.withMessage('Enter vaild mobile No.', minLength(10))
                },
                urlbtntext: {
                    requiredIf: helpers.withMessage(
                        "Please enter button text",
                        requiredIf(
                            this.wht.websiteurllink != ''
                        )
                    ),
                },
                websiteurllink: {
                    requiredIf: helpers.withMessage(
                        "Please enter website URL",
                        requiredIf(
                            this.wht.urlbtntext != ''
                        )
                    ),
                },
            },
            imsg: {
                templatename: {
                    required: helpers.withMessage("Please enter template name", required),
                },
                whtheaderimage: {
                    required: helpers.withMessage("Please upload image", required),
                },
                whtheadervideo: {
                    required: helpers.withMessage("Please upload video", required),
                },
                imessage: {
                    required: helpers.withMessage("Please enter message", required),
                },
                firstbtnname: {
                    required: helpers.withMessage("Please enter button name", required),
                },
                secondbtnname: {
                    required: helpers.withMessage("Please enter button name", required),
                },
            },
            sms: {
                senderid: {
                    required: helpers.withMessage("Please select sender id", required),
                },
                vendortemplateid: {
                    required: helpers.withMessage("Please enter vendor template id", required),
                    minLength: helpers.withMessage(
                        "Template id should be min 10 length",
                        minLength(10)
                    ),
                },
                templatename: {
                    required: helpers.withMessage("Please enter template name", required),
                },
                aliasname: {
                    required: helpers.withMessage("Please enter alias name", required),
                },
                smsmessage: {
                    required: helpers.withMessage("Please enter message", required),
                },
            },
            email: {
                templatename: {
                    required: helpers.withMessage("Please enter template name", required),
                },
                emailmessage: {
                    required: helpers.withMessage("Please enter message", required),
                },
            },
            audio: {
                templatename: {
                    required: helpers.withMessage("Please enter template name", required),
                },
                languagevalue: {
                    required: helpers.withMessage("Please select language", required),
                },
                responsetype: {
                    required: helpers.withMessage("Please select response type", required),
                },
                headeraudio: {
                    required: helpers.withMessage("Please upload audio file", required),
                },
                // response one
                responsevalueone: {
                    requiredIf: helpers.withMessage(
                        "Please enter response value",
                        requiredIf(
                            this.audio.responsetype == 1
                        )
                    ),
                },
                responsestatusone: {
                    requiredIf: helpers.withMessage(
                        "Please select response status",
                        requiredIf(
                            this.audio.responsetype == 1
                        )
                    ),
                },
                // response two
                responsevaluetwo: {
                    requiredIf: helpers.withMessage(
                        "Please enter response value",
                        requiredIf(
                            this.audio.responsetype == 1
                        )
                    ),
                },
                responsestatustwo: {
                    requiredIf: helpers.withMessage(
                        "Please select response status",
                        requiredIf(
                            this.audio.responsetype == 1
                        )
                    ),
                },
                // response three
                responsevaluethree: {
                    requiredIf: helpers.withMessage(
                        "Please enter response value",
                        requiredIf(
                            this.audio.responsestatusthree == true
                        )
                    ),
                },
                responsestatusthree: {
                    requiredIf: helpers.withMessage(
                        "Please select response status",
                        requiredIf(
                            this.audio.responsevaluethree != ''
                        )
                    ),
                },
                // response four
                responsevaluefour: {
                    requiredIf: helpers.withMessage(
                        "Please enter response value",
                        requiredIf(
                            this.audio.responsestatusfour == true
                        )
                    ),
                },
                responsestatusfour: {
                    requiredIf: helpers.withMessage(
                        "Please select response status",
                        requiredIf(
                            this.audio.responsevaluefour != ''
                        )
                    ),
                },
                // response five
                responsevaluefive: {
                    requiredIf: helpers.withMessage(
                        "Please enter response value",
                        requiredIf(
                            this.audio.responsestatusfive == true
                        )
                    ),
                },
                responsestatusfive: {
                    requiredIf: helpers.withMessage(
                        "Please select response status",
                        requiredIf(
                            this.audio.responsevaluefive != ''
                        )
                    ),
                },
                // response six
                responsevaluesix: {
                    requiredIf: helpers.withMessage(
                        "Please enter response value",
                        requiredIf(
                            this.audio.responsestatussix == true
                        )
                    ),
                },
                responsestatussix: {
                    requiredIf: helpers.withMessage(
                        "Please select response status",
                        requiredIf(
                            this.audio.responsevaluesix != ''
                        )
                    ),
                },
                // response seven
                responsevalueseven: {
                    requiredIf: helpers.withMessage(
                        "Please enter response value",
                        requiredIf(
                            this.audio.responsestatusseven == true
                        )
                    ),
                },
                responsestatusseven: {
                    requiredIf: helpers.withMessage(
                        "Please select response status",
                        requiredIf(
                            this.audio.responsevalueseven != ''
                        )
                    ),
                },
                // response eight
                responsevalueeight: {
                    requiredIf: helpers.withMessage(
                        "Please enter response value",
                        requiredIf(
                            this.audio.responsestatuseight == true
                        )
                    ),
                },
                responsestatuseight: {
                    requiredIf: helpers.withMessage(
                        "Please select response status",
                        requiredIf(
                            this.audio.responsevalueeight != ''
                        )
                    ),
                },
                // response nine
                responsevaluenine: {
                    requiredIf: helpers.withMessage(
                        "Please enter response value",
                        requiredIf(
                            this.audio.responsestatusnine == true
                        )
                    ),
                },
                responsestatusnine: {
                    requiredIf: helpers.withMessage(
                        "Please select response status",
                        requiredIf(
                            this.audio.responsevaluenine != ''
                        )
                    ),
                },
            },

        };
    },
    watch: {
        websiteurllink(websiteurllink) {
            this.errmsg = "";
            this.validateUrl(websiteurllink);
            if (websiteurllink.length === 0) {
                this.wht.websiteurllinkErr = "";
                this.errStatus = false;
            }
        },
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getlanguages();
        this.getSenderIdList();
        this.getwhatsappResponseTemplateList();
    },
    methods: {
        selectedtab(e) {
            this.selected_tab = e;
        },
        getSenderIdList(e) {
            this.ApiService.getSenderIdList(e).then((data) => {
                if (data.status == 200) {
                    this.senderidlist = data.data;
                } else {
                    this.senderidlist = [];
                }
            });
        },
        getwhatsappResponseTemplateList(e) {
            this.ApiService.getwhatsappResponseTemplateList(e).then((data) => {
                if (data.status == 200) {
                    this.responselist = data.data;
                } else {
                    this.responselist = [];
                }
            });
        },
        showTemplateSmsCountDown: function () {
            this.templatenameRemainCountTotal = this.templatenameMxLimitCount - this.templatename.length;
        },
        showTemplateSmsBodyCountDown: function () {
            this.templatebodyRemainCountTotal = this.templatebodyMxLimitCount - this.whatsappmessage.length;
        },
        intelligentsubmitbtn() {
            this.v$.int.$validate();
            var formData = new FormData();
            formData.append("al2", this.int.templatename);
            formData.append("al3", this.int.intelligentmessage);
            if (!this.v$.int.$error) {
                this.$store.dispatch("addCampaignStatus");
            } else {
                this.int.$error;
            }
        },
        whatsappsubmitbtn() {
            this.v$.wht.$validate();
            var formData = new FormData();
            formData.append("cg28", 1);
            formData.append("cg3", this.wht.templatename);
            formData.append("cg2", this.wht.aliasname);
            if (this.wht.categorytype) {
                formData.append('cg21', this.wht.categorytype.value);
                // formData.append('ak11', this.wht.categorytype.label);
            }
            if (this.wht.languagevalue) {
                formData.append('cg12', this.wht.languagevalue.value);
                formData.append('cg13', this.wht.languagevalue.label);
            }
            formData.append("cg15", this.wht.headertype);
            if (this.wht.headertype == 1) {
                formData.append('cg16', this.wht.mediatype.value);
                if (this.wht.mediatype.value == 1) {
                    if (this.wht.whtheaderimage) {
                        formData.append("cg17", this.wht.whtheaderimage);
                    }
                } else if (this.wht.mediatype.value == 2) {
                    if (this.wht.whtheadervideo) {
                        formData.append("cg17", this.wht.whtheadervideo);
                    }
                } else if (this.wht.mediatype.value == 3) {
                    if (this.wht.whtheaderdoc) {
                        formData.append("cg17", this.wht.whtheaderdoc);
                    }
                }
            }
            formData.append("cg5", this.wht.whatsappmessage);
            if (this.wht.footername) {
                formData.append("cg23", this.wht.footername);
            }
            if (this.wht.firstbtnname != '' || this.wht.callbtntext != '' || this.wht.urlbtntext != '') {
                formData.append('cg20', this.wht.buttontype.value);
            }
            if (this.wht.buttontype.value == 1) {
                if (this.wht.firstbtnname) {
                    formData.append("cg6", this.wht.firstbtnname);
                }
                if (this.wht.secondbtnname) {
                    formData.append("cg8", this.wht.secondbtnname);
                }
                if (this.wht.thirdbtnname) {
                    formData.append("cg10", this.wht.thirdbtnname);
                }
                if(this.wht.firstbtnname != '') {
                    if(this.wht.firstbtnresponse != '') {
                        formData.append("cg7", this.wht.firstbtnresponse);
                    }
                }
                if(this.wht.secondbtnname != '') {
                    if(this.wht.secondbtnresponse != '') {
                        formData.append("cg9", this.wht.secondbtnresponse);
                    }
                }
                if(this.wht.thirdbtnname != '') {
                    if(this.wht.thirdbtnresponse != '') {
                        formData.append("cg11", this.wht.thirdbtnresponse);
                    }
                }
            }
            if (this.wht.buttontype.value == 2) {
                if (this.wht.callbtntext) {
                    formData.append("cg24", this.wht.callbtntext);
                }
                if (this.wht.callmobileno) {
                    formData.append("cg25", this.wht.callmobileno);
                }
                if (this.wht.urlbtntext) {
                    formData.append("cg26", this.wht.urlbtntext);
                }
                if (this.wht.websiteurllink) {
                    formData.append("cg27", this.wht.websiteurllink);
                }
            }

            if (!this.v$.wht.$error && !this.wht.websiteurllinkErr && !this.wht.errorwhtheaderimage && !this.wht.errorwhtheadervideo && !this.wht.errorwhtheaderdoc) {
                this.wht.whtbtnloader = true;
                this.ApiService.addWhatsappCampaignTemplate(formData).then((data) => {
                    if (data.status == 200) {
                        this.showloader = false;
                        this.$store.dispatch("addCampaignStatus");
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.redirectwhatappcampaigntemplate();
                    } else {
                        this.showloader = false;
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                    }
                });

            } else {
                this.$error;
            }

        },
        imessagesubmitbtn() {
            this.v$.imsg.$validate();
            var formData = new FormData();
            formData.append("al2", this.imsg.templatename);
            formData.append("al3", this.imsg.imessage);
            formData.append("al5", this.imsg.firstbtnname);
            formData.append("al6", this.imsg.secondbtnname);
            formData.append("al7", this.imsg.thirdbtnname);
            if (this.imsg.whtheaderimage) {
                formData.append("al4", this.imsg.whtheaderimage);
            }
            if (this.imsg.whtheadervideo) {
                formData.append("al4", this.imsg.whtheadervideo);
            }
            if (!this.v$.imsg.$error) {
                console.log("success");
            } else {
                this.imsg.$error;
            }

        },
        smsSubmitBtn() {
            this.v$.sms.$validate();
            let fields = {};
            if(this.sms.senderid) {
                fields["cj2"] = this.sms.senderid.value;
                fields["cj3"] = this.sms.senderid.label;
            }
            fields["cj6"] = this.sms.vendortemplateid;
            fields["cj5"] = this.sms.templatename;
            fields["cj4"] = this.sms.aliasname;
            fields["cj7"] = this.sms.smsmessage;
            if (!this.v$.sms.$error && !this.duplicateTemplateMsg) {
                this.smsgbtnloader = true;
                this.ApiService.addSmsCampaignTemplate(fields).then((data) => {
                    if (data.status == 200) {
                        this.smsgbtnloader = false;
                        this.$store.dispatch("addCampaignStatus");
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.redirectsmscampaigntemplate();
                    } else {
                        this.smsgbtnloader = false;
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                    }
                });

            } else {
                this.$error;
            }

        },
        emailsubmitbtn() {
            this.v$.email.$validate();
            var formData = new FormData();
            formData.append("al2", this.email.templatename);
            formData.append("al3", this.email.emailmessage);
            if (!this.v$.email.$error) {
                console.log("success");
            } else {
                this.email.$error;
            }
        },
        handleimsgImageUploader() {

        },
        handleimsgVideoUploader() {

        },
        getlanguages(e) {
            this.ApiService.getlanguages(e).then((data) => {
                if (data.status == 200) {
                    this.languagelist = data.data;
                } else {
                    this.languagelist = [];
                }
            });
        },
        showTemplateCountDown: function () {
            this.templatenameRemainCountTotal = this.templatenameMxLimitCount - this.wht.templatename.length;
        },
        showTemplateBodyCountDown: function () {
            this.templatebodyRemainCountTotal = this.templatebodyMxLimitCount - this.wht.whatsappmessage.length;
        },
        handleWhtImageUploader() {
            var file = this.$refs.whtheaderimage.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file) {
                this.wht.errorwhtheaderimage = 'Please upload image';
            } else if (!file.size > 2048) {
                this.wht.errorwhtheaderimage = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.wht.errorwhtheaderimage = 'Invalid file type';
            } else {
                this.wht.errorwhtheaderimage = '';
                this.wht.whtheaderimage = this.$refs.whtheaderimage.files[0];
                this.wht.exitwhatheaderpreviewimgurl = this.wht.whtheaderimage;
            }
        },
        handleWhtVideoUploader() {
            var file = this.$refs.whtheadervideo.files[0];
            let allowedExtensions = /(\.mp4|\.mpeg)$/i;
            if (!file) {
                this.wht.errorwhtheadervideo = 'Please upload Video';
            } else if (!file.size > 5120) {
                this.wht.errorwhtheadervideo = 'File size cannot exceed more than 5MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.wht.errorwhtheadervideo = 'Invalid file type';
            } else {
                this.wht.errorwhtheadervideo = '';
                this.wht.whtheadervideo = this.$refs.whtheadervideo.files[0];
                this.wht.exitwhatheaderpreviewvideourl = this.wht.whtheadervideo;
                this.previewVideo();
            }
        },
        previewVideo() {
            let video = document.getElementById('video-preview');
            let reader = new FileReader();

            reader.readAsDataURL(this.wht.whtheadervideo);
            reader.addEventListener('load', function () {
                video.src = reader.result;
            });
        },
        handleWhtDocUploader() {
            var file = this.$refs.whtheaderdoc.files[0];
            let allowedExtensions = /(\.doc|\.docx|\.pdf|\.xml)$/i;
            if (!file) {
                this.wht.errorwhtheaderdoc = 'Please upload file';
            } else if (!file.size > 2048) {
                this.wht.errorwhtheaderdoc = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.wht.errorwhtheaderdoc = 'Invalid file type';
            } else {
                this.wht.errorwhtheaderdoc = '';
                this.wht.whtheaderdoc = this.$refs.whtheaderdoc.files[0];
                this.wht.exitwhatheaderpreviewdovurl = this.wht.whtheaderdoc;
            }
        },
        clearheader() {
            this.wht.mediatype = '';
            this.wht.whtheaderimage = null;
            this.wht.errorwhtheaderimage = '';
            this.wht.exitwhatheaderpreviewimgurl = null;
            this.wht.whtheadervideo = null;
            this.wht.errorwhtheadervideo = '';
            this.wht.exitwhatheaderpreviewvideourl = null;
            this.wht.whtheaderdoc = null;
            this.wht.errorwhtheaderdoc = '';
            this.wht.exitwhatheaderpreviewdovurl = null;
        },
        mediatypechanges() {
            this.wht.mediatype = '';
            this.wht.whtheaderimage = null;
            this.wht.errorwhtheaderimage = '';
            this.wht.exitwhatheaderpreviewimgurl = null;
            this.wht.whtheadervideo = null;
            this.wht.errorwhtheadervideo = '';
            this.wht.exitwhatheaderpreviewvideourl = null;
            this.wht.whtheaderdoc = null;
            this.wht.errorwhtheaderdoc = '';
            this.wht.exitwhatheaderpreviewdovurl = null;
        },
        redirectwhatappcampaigntemplate() {
            this.$router.push("/campaigntemplate/whatsapp");
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
        buttontypechanges() {
            this.wht.buttontype = '';
            this.wht.firstbtnname = '';
            this.wht.firstbtnresponse = '';
            this.wht.secondbtnresponse = '';
            this.wht.thirdbtnresponse = '';
            this.wht.secondbtnname = '';
            this.wht.thirdbtnname = '';
            this.wht.callbtntext = '';
            this.wht.callmobileno = '';
            this.wht.urlbtntext = '';
            this.wht.websiteurllink = '';
        },
        validateUrl(url) {
            this.errStatus = false;
            if (!this.validUrl(url)) {
                this.wht.websiteurllinkErr = "Please enter a valid URL";
                this.errStatus = true;
            }
            if (this.errStatus === false) {
                this.wht.websiteurllinkErr = "";
                return true;
            }
        },

        validUrl: function (url) {
            var re = /^(?!((ftp|http(s?)):\/\/))?[^ "]+(\.[a-zA-Z]{2,})+$/;
            return re.test(url);
        },
        htmlconvertmsg() {
            var messagevalue = this.wht.whatsappmessage;
            this.whatsappmessageinput = messagevalue.replace(/\n/g, '<br/>');
        },
        onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
        redirectsmscampaigntemplate() {
            window.location.href = "/campaigntemplate/sms";
        },
        checkTemplateName() {
            if ((this.sms.templatename).length > 3 && localStorage.getItem('sms_templates') !== null) {
                var templates = JSON.parse(localStorage.getItem('sms_templates'));
                for (var i = 0, len = templates.length; i < len; i++) {
                    if ((templates[i]['cg3']).toLowerCase() === (this.sms.templatename).toLowerCase()) {
                        this.duplicateTemplateMsg = 'Template name already exits.';
                        break;
                    } else {
                        this.duplicateTemplateMsg = '';
                    }
                }
            }
        },
        htmlsmsconvertmsg() {
            var messagevalue = this.sms.smsmessage;
            this.sms.smsmessageinput = messagevalue.replace(/\n/g, '<br/>');
        },
        // Audio Campaign function start here 
        addMoreThirdRow() {
            this.thirdrowshowstatus = true;
            this.thirdrowbtnstatus = false;
            this.fourthrowbtnstatus = true;
        },
        addMoreFourthRow() {
            this.fourthrowshowstatus = true;
            this.fourthrowbtnstatus = false;
            this.fifthrowbtnstatus = true;
        },

        addMoreFifthRow() {
            this.fifthrowshowstatus = true;
            this.fifthrowbtnstatus = false;
            this.sixthrowbtnstatus = true;
        },

        addMoreSixthRow() {
            this.sixthrowshowstatus = true;
            this.sixthrowbtnstatus = false;
            this.seventhrowbtnstatus = true;
        },

        addMoreSeventhRow() {
            this.seventhrowshowstatus = true;
            this.seventhrowbtnstatus = false;
            this.eighthrowbtnstatus = true;
        },

        addMoreEighthRow() {
            this.eighthrowshowstatus = true;
            this.eighthrowbtnstatus = false;
            this.ninethrowbtnstatus = true;
        },

        addMoreNinethRow() {
            this.ninethrowshowstatus = true;
            this.ninethrowbtnstatus = false;
        },
        audiosubmitbtn() {
            this.v$.audio.$validate();
            var formData = new FormData();
            formData.append("cl2", this.audio.templatename);
            if (this.audio.languagevalue) {
                formData.append('cl7', this.audio.languagevalue.value);
            }
            formData.append("cl8", this.audio.responsetype);
            if (this.audio.headeraudio != null) {
                formData.append("media_file", this.audio.headeraudio);
            }
            if (this.audio.responsetype == 1) {
                formData.append("cl9", this.audio.responsenumberone);
                formData.append("cl10", this.audio.responsevalueone);
                if (this.audio.responsestatusone == true) {
                    formData.append("cl11", 1);
                } else {
                    formData.append("cl11", 0);
                }

                formData.append("cl12", this.audio.responsenumbertwo);
                formData.append("cl13", this.audio.responsevaluetwo);
                if (this.audio.responsestatustwo == true) {
                    formData.append("cl14", 1);
                } else {
                    formData.append("cl14", 0);
                }

                formData.append("cl15", this.audio.responsenumberthree);
                formData.append("cl16", this.audio.responsevaluethree);
                if (this.audio.responsestatusthree == true) {
                    formData.append("cl17", 1);
                } else {
                    formData.append("cl17", 0);
                }

                formData.append("cl18", this.audio.responsenumberfour);
                formData.append("cl19", this.audio.responsevaluefour);
                if (this.audio.responsestatusfour == true) {
                    formData.append("cl20", 1);
                } else {
                    formData.append("cl20", 0);
                }

                formData.append("cl21", this.audio.responsenumberfive);
                formData.append("cl22", this.audio.responsevaluefive);
                if (this.audio.responsestatusfive == true) {
                    formData.append("cl23", 1);
                } else {
                    formData.append("cl23", 0);
                }

                formData.append("cl24", this.audio.responsenumbersix);
                formData.append("cl25", this.audio.responsevaluesix);
                if (this.audio.responsestatussix == true) {
                    formData.append("cl26", 1);
                } else {
                    formData.append("cl26", 0);
                }

                formData.append("cl27", this.audio.responsenumberseven);
                formData.append("cl28", this.audio.responsevalueseven);
                if (this.audio.responsestatusseven == true) {
                    formData.append("cl29", 1);
                } else {
                    formData.append("cl29", 0);
                }

                formData.append("cl30", this.audio.responsenumbereight);
                formData.append("cl31", this.audio.responsevalueeight);
                if (this.audio.responsestatuseight == true) {
                    formData.append("cl32", 1);
                } else {
                    formData.append("cl32", 0);
                }

                formData.append("cl33", this.audio.responsenumbernine);
                formData.append("cl34", this.audio.responsevaluenine);
                if (this.audio.responsestatusnine == true) {
                    formData.append("cl35", 1);
                } else {
                    formData.append("cl35", 0);
                }
            }

            if (!this.v$.audio.$error && !this.errortuneaddfile && !this.duplicateTemplateAudioMsg) {
                this.audiogbtnloader = true;
                this.ApiService.addCallCampaignTemplate(formData).then((data) => {
                    if (data.success == true) {
                        this.audiogbtnloader = false;
                        this.$store.dispatch("addCampaignStatus");
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.redirectaudiocampaigntemplate();
                    } else {
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.audiogbtnloader = false;
                    }
                });
            } else {
                this.$error;
            }
        },
        handlerAudioUploader() {
            var file = this.$refs.headeraudioref.files[0];
            let allowedExtensions = /(\.mp3)$/i;
            if (!file.name.match(allowedExtensions)) {
                this.errortuneaddfile = 'Invalid file type';
            } else {
                this.errortuneaddfile = '';
                this.audio.headeraudio = this.$refs.headeraudioref.files[0];
                this.exitaddpreviewurl = this.audio.headeraudio;
            }
        },

        checkSmsTemplateName() {
            if ((this.audio.templatename).length > 3 && localStorage.getItem('sms_templates') !== null) {
                var templates = JSON.parse(localStorage.getItem('sms_templates'));
                for (var i = 0, len = templates.length; i < len; i++) {
                    if ((templates[i]['cl2']).toLowerCase() === (this.audio.templatename).toLowerCase()) {
                        this.duplicateTemplateAudioMsg = 'Template name already exits.';
                        break;
                    } else {
                        this.duplicateTemplateAudioMsg = '';
                    }
                }
            }
        },
        redirectaudiocampaigntemplate() {
            window.location.href = "/campaigntemplate/audiocall";
        },
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        onlyResponseOneFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberone = document.getElementById("responsenumberone");
            if ((responsenumberone.value == '' || responsenumberone.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        onlyResponseTwoFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbertwo = document.getElementById("responsenumbertwo");
            if ((responsenumbertwo.value == '' || responsenumbertwo.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },


        onlyResponseThreeFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberthree = document.getElementById("responsenumberthree");
            if ((responsenumberthree.value == '' || responsenumberthree.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseFourFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberfour = document.getElementById("responsenumberfour");
            if ((responsenumberfour.value == '' || responsenumberfour.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseFiveFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberfive = document.getElementById("responsenumberfive");
            if ((responsenumberfive.value == '' || responsenumberfive.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseSixFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbersix = document.getElementById("responsenumbersix");
            if ((responsenumbersix.value == '' || responsenumbersix.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseSevenFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberseven = document.getElementById("responsenumberseven");
            if ((responsenumberseven.value == '' || responsenumberseven.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseEightFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbereight = document.getElementById("responsenumbereight");
            if ((responsenumbereight.value == '' || responsenumbereight.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseNineFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbernine = document.getElementById("responsenumbernine");
            if ((responsenumbernine.value == '' || responsenumbernine.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        clearresponse() {
            this.responsevalueone = '';
            this.responsestatusone = '';

            this.responsevaluetwo = '';
            this.responsestatustwo = '';

            this.responsevaluethree = '';
            this.responsestatusthree = '';

            this.responsevaluefour = '';
            this.responsestatusfour = '';

            this.responsevaluefive = '';
            this.responsestatusfive = '';

            this.responsevaluesix = '';
            this.responsestatussix = '';

            this.responsevalueseven = '';
            this.responsestatusseven = '';

            this.responsevalueeight = '';
            this.responsestatuseight = '';

            this.responsevaluenine = '';
            this.responsestatusnine = '';
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        changeStatusOne(e) {
            if (e == true) {
                this.responsestatusone = 1;
            } else if (e == false) {
                this.responsestatusone = 0;
            }
        }
        // Audio campaign function end here
    },
};
</script>
<style scoped>
.campaign-modal-outer-border {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}


.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.campaign-modal-outer-border {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.intelligent-campagin-modal-header {
    font-family: 'AcuminPro-SemiBold';
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0B294B;
    line-height: 18px;
    margin-top: 10px;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.card-template {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 0px 15px;
}

.preview-card-template {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 14px 15px;
    height: auto;
    min-height: 197px;
}

.preview-body-msg {
    font-family: "AcuminPro-Regular";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 17px;
    margin-top: 15px;
}

.preview-footer-msg {
    margin-top: 15px;
}
</style>