<template>
    <div class="modal-mask">
        <div class="
        modal-dialog modal-lg modal-dialog-centered
        custom-modal-outer
      ">
            <div class="modal-content modal-outer">
                <div class="row g-2">
                    <div class="col-lg-7 col-md-7 col-12">
                        <div class="text-center custom-img-outer">
                            <img v-if="this.client_info.maa59 == null || this.client_info.maa59 == ''"
                                src="/assets/images/login-banner.jpg" class="img-fluid" alt="" />
                            <img v-if="this.client_info.maa59 != null && this.client_info.maa59 != ''" class="img-fluid"
                                :src="party_banner" alt="" />
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12">
                        <div class="p-3 border custom-card-outer h-100">
                            <div class="text-center">
                                <div class="mb-3">
                                    <img v-if="(this.uservoterphoto == null || this.uservoterphoto == '') && (this.useraadharphoto == null || this.useraadharphoto == '')"
                                        src="/assets/images/meraneta-logo-black.png" class="img-fluid"
                                        alt="party-head-img" width="100" />
                                    <img v-if="this.uservoterphoto != null && this.uservoterphoto != '' && this.profilesettype == 2"
                                        :src="user_votercard_photo" class="rounded-circle login-img-border"
                                        alt="user-img" width="100" height="100" />
                                    <img v-if="(this.useraadharphoto != null && this.useraadharphoto != '') && this.profilesettype == 1"
                                        :src="user_aadhar_photo" class="rounded-circle login-img-border" alt="user-img"
                                        width="100" height="100" />
                                </div>
                                <div class="mb-3 custom-user-name text-capitalize">
                                    {{ userfullname }}
                                </div>
                                <div class="horizontal-line mb-3"></div>
                                <div class="mb-4 custom-user-content text-secondary">
                                    <div>You have been away from Lok Neta System</div>
                                    <div>for 60 minutes.</div>
                                    <div>Please enter your password again to continue.</div>
                                </div>
                                <div class="col-12">
                                    <div class="custom-form-group">
                                        <div class="input-group">
                                            <input class="form-control custom-input-passward"
                                                placeholder="Enter Password" aria-label="Password"
                                                v-model="relogin.password" :type="passwordVisibility" maxlength="12"
                                                v-on:keyup.enter="reloginbtn()" aria-describedby="formPasswordInput"
                                                autocomplete="off" tabindex="1" />
                                            <span class="input-group-text" id="formPasswordInput">
                                                <button @click="showPasswordType()"
                                                    v-if="passwordVisibility == 'password'"
                                                    class="btn btn-link login-password-type" tabindex="0">
                                                    <i class="pi pi-eye-slash"></i>
                                                </button>
                                                <button @click="hidePasswordType()" v-if="passwordVisibility == 'text'"
                                                    class="btn btn-link login-password-type" tabindex="0">
                                                    <i class="pi pi-eye"></i>
                                                </button>
                                            </span>
                                        </div>
                                        <div class="custom-error" v-if="v$.relogin.password.$error">
                                            {{ v$.relogin.password.$errors[0].$message }}
                                        </div>
                                        <div class="custom-error" v-if="relogin.password != '' && errormsg">
                                            {{ errormsg }}
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <button type="button" class="btn modal-step-next-btns" @click="reloginbtn()"
                                            :disabled="showloader" tabindex="2">
                                            <span v-if="!showloader">Submit</span>
                                            <div class="
                                            spinner-border
                                            text-light
                                            custom-spinner-loader-btn
                                            " v-if="showloader"></div>
                                        </button>
                                    </div>
                                </div>
                                <divider class="mt-3 mb-3" />
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <button class="btn btn-link account-statement-link-btn" :disabled="logoutshowloader" @click="logout()">
                                            <span v-if="!logoutshowloader">Sign Out</span>
                                            <div class="
                                            spinner-border
                                            text-light
                                            custom-spinner-loader-btn
                                            " v-if="logoutshowloader"></div></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../service/ApiService";
import { required, helpers, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
    data() {
        return {
            v$: useValidate(),
            passwordVisibility: "password",
            showloader: false,
            showReloginPopup: false,
            userloginid: "",
            relogin: {
                password: "",
            },
            userfullname: "",
            client_info: "",
            user_votercard_photo: "",
            useraadharphoto: '',
            baseUrl: window.location.origin,
            party_banner: "",
            client_path: '',
            uservoterphoto: '',
            user_aadhar_photo: '',
            partyshortname: '',
            voterstorageimgpath: '',
            errormsg: '',
            profilesettype: '',
            logoutshowloader: false,
        };
    },
    mounted() {
        var userData = JSON.parse(localStorage.user);
        this.userloginid = userData.ak24;
        this.uservoterphoto = userData.ak90;
        this.useraadharphoto = userData.ak33;
        this.profilesettype = userData.ak151;
        this.userfullname = userData.user_short_name;
        // localStorage["masterbucketname"] = 'meraneta_admin';
        if (
            localStorage.error_toaster_display &&
            localStorage.error_toaster_display == 1
        ) {
            this.$toast.open({
                message: localStorage.logout_reason,
                type: "error",
                duration: 3000,
                position: "top-right",
            });
            localStorage.clear();
        }
        this.client_info = JSON.parse(localStorage.client_info);
        this.partyshortname = this.client_info.maa65;
        if (this.baseUrl == 'http://localhost:8080' || this.baseUrl == 'http://localhost:8081') {
            this.client_path = this.client_info.maa24;
        } else {
            this.client_path = localStorage.masterbucketname;
        }
        this.voterstorageimgpath = this.client_info.maa24;
        this.user_votercard_photo = "https://storage.googleapis.com/" + this.voterstorageimgpath + "/voterphoto/" + this.uservoterphoto;
        this.user_aadhar_photo = "https://storage.googleapis.com/" + this.voterstorageimgpath + "/aadharphoto/" + this.useraadharphoto;
        this.party_banner = "https://storage.googleapis.com/" + this.client_path + "/Client/PartyBanner/" + this.client_info.maa59;
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    // watch: {
    //   password() {
    //     this.v$.$validate();
    //   },
    // },

    validations() {
        return {
            relogin: {
                password: {
                    required: helpers.withMessage("Please enter password", required),
                    maxLength: helpers.withMessage(
                        "Password should be max 12 length",
                        maxLength(12)
                    ),
                },
            }
        };
    },

    methods: {
        showPasswordType() {
            this.passwordVisibility = "text";
        },

        hidePasswordType() {
            this.passwordVisibility = "password";
        },


        reloginbtn() {
            this.v$.relogin.$validate();
            let fields = {};
            fields["login_id"] = this.userloginid;
            fields["login_password"] = this.relogin.password;
            if (!this.v$.relogin.$error) {
                this.showloader = true;
                this.ApiService.login(fields).then((items) => {
                    if (items.status === 200) {
                        this.showReloginPopup = false;
                        this.showloader = false;
                        var currentTime = new Date().toUTCString();
                        localStorage["last_login_time"] = currentTime;
                        localStorage["logoutUser"] = 0;
                        localStorage["sessionStandBy"] = 0;
                        localStorage["permission_fks"] = JSON.stringify(items.data.permission_fks);
                        localStorage["user_reference"] = items.data.user_reference;

                        // var successMsg = items.message;
                        // this.$toast.open({
                        //     message: successMsg,
                        //     type: "success",
                        //     duration: 3000,
                        //     position: "top-right",
                        // });
                        location.reload();
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.errormsg = "";
                    } else {
                        this.errormsg = items.message;
                        this.showloader = false;
                        this.$toast.open({
                            message: items.message,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                    }
                });
            }
        },
        logout() {
            this.logoutshowloader = true;
            this.ApiService.logout().then((data) => {
                if (data.status == 200) {
                var successMsg = data.message;
                this.$toast.open({
                    message: successMsg,
                    type: "success",
                    duration: 3000,
                    position: "top-right",
                });
                setTimeout(() => {
                    this.v$.$reset();
                }, 0);
                this.logoutshowloader = false;
                } else {
                var errorMsg = data.message;
                if (errorMsg != '') {
                    this.$toast.open({
                    message: errorMsg,
                    type: "error",
                    duration: 3000,
                    position: "top-right",
                    });
                }
                this.logoutshowloader = false;
                }
            });
        },
    },
};
</script>
<style scoped>
.modal-content.modal-outer {
    padding: 10px;
}

.horizontal-line {
    border: 1px solid #ebeef5;
    opacity: 1;
}

.custom-user-name {
    letter-spacing: 0.32px;
    color: #2b2e38;
    opacity: 1;
    font-size: 20px;
    font-family: "AcuminPro-Regular";
}

.custom-user-content {
    letter-spacing: 0.26px;
    color: #4a5463;
    opacity: 1;
    font-size: 14px;
    font-family: "AcuminPro-Regular";
}

.modal-dialog-scrollable .modal-content {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #e9e9e4;
}

.modal-dialog-scrollable .modal-content::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.modal-dialog-scrollable .modal-content::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.custom-form-group .custom-error {
    color: #de2525;
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.52px;
    line-height: 20px;
    position: absolute;
}

.custom-form-group {
    margin-bottom: 40px;
}

.custom-logo {
    background: #2a2a45 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #000;
    padding: 12px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 50px;
}

.modal-step-next-btns.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
    min-width: 150px;
    width: 100%;
}

.modal-step-next-btns.btn:focus,
.modal-step-next-btns.btn:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.custom-img-outer {
    height: 100%;
    background-color: #ababab;
}

.custom-img-outer img {
    /* height: 365px; */
    width: 100%;
    height: 100%;
}

.custom-card-outer {
    background-color: #ffffff;
}
.account-statement-link-btn:hover {
  box-shadow: none !important;
  color: #de2525;
}

.account-statement-link-btn {
  padding: 0;
  font-family: "AcuminPro-Regular";
  font-size: 18px;
  letter-spacing: 0.24px;
  color: #de2525;
  line-height: 18px;
  text-decoration: none;
}
</style>