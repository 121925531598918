<template>
  <div >
    <table
      class="table custom-overview-table-outer align-middle table-responsive"
    >
      <thead>
        <tr>
          <td scope="col" style="width: 50%">Organization Name</td>
          <td scope="col" style="width: 30%">Designation</td>
          <td scope="col" style="width: 20%" class="text-center">Involvement Level</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 50%">Gokul Dairy Farm</td>
          <td style="width: 30%">Vice Chairman</td>
          <td style="width: 20%" class="text-center">
            <span :class="'user-badge status-active'">High</span>
          </td>
        </tr>
        <tr>
          <td style="width: 50%">Ankur Housing Society</td>
          <td style="width: 30%">Secretary</td>
          <td style="width: 20%" class="text-center">
            <span :class="'user-badge status-temp-suspend'">Middle</span>
          </td>
        </tr>
        <tr>
          <td style="width: 50%">Swami Sahakari Group</td>
          <td style="width: 30%">Clarks</td>
          <td style="width: 20%" class="text-center">
            <span :class="'user-badge status-per-suspend'">Low</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
            
<script>
export default {};
</script>            
<style>
/* custom table css start here  */
.custom-overview-table-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.custom-overview-table-outer thead {
  background: #f2f4fb 0% 0% no-repeat padding-box;
}
.custom-overview-table-outer thead tr td,
.custom-overview-table-outer thead tr th {
  border-bottom-width: 0px;
}
.custom-overview-table-outer thead tr td,
.custom-overview-table-outer thead tr th {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
}
.custom-overview-table-outer tbody tr td,
.custom-overview-table-outer tbody tr th {
  font-family: "AcuminPro-SemiBold";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
}
.custom-overview-table-outer thead tr td,
.custom-overview-table-outer thead tr th {
  padding: 10px 16px;
}
.custom-overview-table-outer tbody tr td,
.custom-overview-table-outer tbody tr th {
  padding: 16px;
}
.custom-overview-table-outer tbody tr td .table-heading {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}
.custom-overview-table-outer tbody tr td .table-heading,
.custom-overview-table-outer tbody tr td .table-subheading {
  padding-bottom: 8px;
}
.custom-view-detail-btn {
  color: #5266e5;
  border-color: #5266e5;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #5266e5;
  border-radius: 4px;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
}
.custom-view-detail-btn:hover {
  color: #5266e5;
}
.custom-view-detail-btn:focus-visible {
  outline: 0;
}
table.custom-overview-table-outer {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.custom-overview-table-outer thead {
  flex: 0 0 auto;
}

.custom-overview-table-outer tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 194px;
}

.custom-overview-table-outer tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.custom-overview-table-outer tbody::-webkit-scrollbar {
  display: block;
}

.custom-overview-table-outer tbody::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.custom-overview-table-outer tbody::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.custom-spinner-loader-btn {
  width: 15px;
  height: 15px;
  border: 2px solid #ced9e9;
  border-right-color: transparent;
}
/* custom table css end here  */
.user-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.user-badge.status-active {
  background: #c8e6c9;
  color: #256029;
}

.user-badge.status-per-suspend {
  background: #ffcdd2;
  color: #c63737;
}
.user-badge.status-temp-suspend {
  background: #ffd8b2;
  color: #805b36;
}

</style>