import { createStore } from "vuex";

export default createStore({
  state: {
    count: 1,
    editcompanymodalstatus: false,
    clientUser:{clientusermodals:false,editClientUserData:{},clientUserBtnStatus:1,userBranchList:[]}, 
    driver:{driverModal:false,editDriverData:{},driverBtnStatus:1},
    itemsPerPage : 10,
    newtempaltemodalstatus: false
  },
  mutations: {
    editcompanymodalstatus(state, status) {
      state.editcompanymodalstatus = status;
    },
    clientUserModalStatus(state,status)
    {
      state.clientUser.clientusermodals = status;      
    },
    addNewClientUser(state,data)
    {
      state.clientUser.editClientUserData = '';    
      state.clientUser.clientUserBtnStatus = data; 
    },
    clientUserInfo(state,data)
    {
      state.clientUser.editClientUserData = data;    
      state.clientUser.clientUserBtnStatus = data.btnstatus; 
    },
    getBranchLocations(state,data)
    {
      state.clientUser.userBranchList = data;
    },
    addCampaignStatus(state) {
      // console.log('coming');
      state.newtempaltemodalstatus = !state.newtempaltemodalstatus;
    },

  },
  actions: {
    editcompanymodalstatus(context, status) {
      context.commit("editcompanymodalstatus", status);
    },
    clientUserModalStatus(context,status)
    {
      context.commit("clientUserModalStatus",status);
    },
    addNewClientUser(context,data)
    {
      context.commit("addNewClientUser",data);
    },
    clientUserInfo(context,data)
    {
      context.commit("clientUserInfo",data);
    },
    getBranchLocations(context,data)
    {
      context.commit("getBranchLocations",data);
    }, 
    addCampaignStatus(context)
    {
      context.commit("addCampaignStatus");
    }, 
  } 
});
