<template>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="custom-form-group">
                <label class="intelligent-campagin-modal-header">Push Notifications Campaign</label>
            </div>
        </div>
    </div>
    <div class="campaign-tab-outer-scroll" :style="{ height: '409px' }">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Template Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="templatename" class="form-control" placeholder="Enter Template Name"
                        maxlength="512" autocomplete="off" v-on:keyup="checkTemplateName()" />
                    <div class="text-muted text-end" v-if="templatename.length != 0">{{
                        templatenameRemainCountTotal
                    }}/512</div>
                    <div class="custom-error" v-if="v$.templatename.$error">
                        {{ v$.templatename.$errors[0].$message }}
                    </div>
                    <div class="custom-error" v-if="duplicateTemplateMsg">
                        {{ duplicateTemplateMsg }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Template Alias Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="templatealiasname" class="form-control" placeholder="Enter Template Alias Name"
                        maxlength="512" autocomplete="off" />
                    <div class="custom-error" v-if="v$.templatealiasname.$error">
                        {{ v$.templatealiasname.$errors[0].$message }}
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Choose Language<span class="text-danger">*</span></label>
                    <Multiselect v-model="languagevalue" :options="languagelist" :searchable="true" label="label"
                        placeholder="Select Language" class="multiselect-custom text-capitalize" :canClear="false"
                        :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                    <div class="custom-error" v-if="v$.languagevalue.$error">
                        {{ v$.languagevalue.$errors[0].$message }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="languagevalue != ''">
            <div class="col-lg-8 col-md-8 col-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="card-template">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Notification Title Name</label>
                                        <input type="text" v-model="titlename" class="form-control" placeholder="Enter Notification Title Name"
                                            maxlength="70" autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-template mt-3">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Header<span class="text-danger">*</span></label>
                                        <div class="custom-group-radio-box-btn">
                                            <input type="radio" class="btn-check" @click="clearheader()"
                                                v-model="headertype" name="none-option" id="headernone" autocomplete="off"
                                                value="0" />
                                            <label class="btn custom-radio-box-btn text-capitalize"
                                                for="headernone">None</label>
                                            <input type="radio" class="btn-check" @click="clearheader()"
                                                v-model="headertype" name="text-option" id="headermedia" autocomplete="off"
                                                value="1" />
                                            <label class="btn custom-radio-box-btn text-capitalize"
                                                for="headermedia">Media</label>
                                        </div>
                                        <div class="custom-error" v-if="v$.headertype.$error">
                                            {{ v$.headertype.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12" v-if="headertype == 1">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Image <span class="text-danger">*</span></label>
                                        <FileUpload mode="basic" accept="image/*" :auto="true" ref="whtheaderimage"
                                            class="custom-upload-btn-ultima" v-model="whtheaderimage"
                                            v-on:change="handleWhtImageUploader()" />
                                        <div class="custom-error" v-if="v$.whtheaderimage.$error">
                                            {{ v$.whtheaderimage.$errors[0].$message }}
                                        </div>
                                        <div class="custom-error" v-if="errorwhtheaderimage">{{ errorwhtheaderimage }}</div>
                                        <div class="text-primary mt-1" v-if="exitwhatheaderpreviewimgurl != null">{{
                                            exitwhatheaderpreviewimgurl.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-template mt-3">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label for="formmessageInput" class="form-label">Message</label>
                                        <textarea type="text" id="formmessageInput" v-model="whatsappmessage"
                                            class="form-control custom-text-area-scroll" placeholder="Enter Message"
                                            v-on:keyup="htmlconvertmsg()" autocomplete="off" rows="3"
                                            maxlength="250" :style="{ resize: 'vertical' }"></textarea>
                                        <div class="custom-error" v-if="v$.whatsappmessage.$error">
                                            {{ v$.whatsappmessage.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-template mt-3 mb-3">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Response Type<span class="text-danger">*</span></label>
                                        <div class="custom-group-radio-box-btn">
                                            <input type="radio" class="btn-check" v-model="responsetype" name="none-option" id="noresponse"
                                                autocomplete="off" @click="clearresponse()" value="0" />
                                            <label class="btn custom-radio-box-btn text-capitalize" for="noresponse">No Response</label>
                                            <input type="radio" class="btn-check" v-model="responsetype" name="with-option" id="withresponse"
                                                autocomplete="off" value="1" />
                                            <label class="btn custom-radio-box-btn text-capitalize" for="withresponse">With Response</label>
                                        </div>
                                        <div class="custom-error" v-if="v$.responsetype.$error">
                                            {{ v$.responsetype.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="responsetype == 1">
                            <div class="col-lg-12 col-md-12 col-12 mb-3">
                                <div class="form-box-outer">
                                    <!-- Response One  -->
                                    <div class="custom-form-group">
                                        <div class="row">
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-model="responsenumberone" id="responsenumberone"
                                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                                        @keypress="onlyResponseOneFormat" autocomplete="off" style="max-width: 12%;" />
                                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                        <input type="text" v-model="responsevalueone" class="form-control"
                                                            placeholder="Enter Response Value" maxlength="30"
                                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                    </span>
                                                </div>
                                                <div class="custom-error" v-if="v$.responsevalueone.$error">
                                                    {{ v$.responsevalueone.$errors[0].$message }}
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <InputSwitch v-model="responsestatusone" :true-value="dynamicTrueActiveValue"
                                                    :false-value="dynamicFalseInactiveValue" class="mt-1"
                                                    @change="changeStatusOne(responsestatusone)" />
                                                <div class="custom-error" v-if="v$.responsestatusone.$error">
                                                    {{ v$.responsestatusone.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-form-group" v-if="secondrowbtnstatus">
                                        <div class="row text-start">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <button type="button" @click="addMoreSecondRow()" class="btn custom-view-detail-btn"><i
                                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider class="mb-3" v-if="secondrowshowstatus" />
                                    <!-- Response Two  -->
                                    <div class="custom-form-group" v-if="secondrowshowstatus">
                                        <div class="row">
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-model="responsenumbertwo" id="responsenumbertwo"
                                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                                        @keypress="onlyResponseTwoFormat" autocomplete="off" style="max-width: 12%;" />
                                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                        <input type="text" v-model="responsevaluetwo" class="form-control"
                                                            placeholder="Enter Response Value" maxlength="30"
                                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                    </span>
                                                </div>
                                                <div class="custom-error" v-if="v$.responsevaluetwo.$error">
                                                    {{ v$.responsevaluetwo.$errors[0].$message }}
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <InputSwitch v-model="responsestatustwo" class="mt-1" />
                                                <div class="custom-error" v-if="v$.responsestatustwo.$error">
                                                    {{ v$.responsestatustwo.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-form-group" v-if="thirdrowbtnstatus">
                                        <div class="row text-start">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <button type="button" @click="addMoreThirdRow()" class="btn custom-view-detail-btn"><i
                                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider class="mb-3" v-if="thirdrowshowstatus" />
                                    <!-- Response Three  -->
                                    <div class="custom-form-group" v-if="thirdrowshowstatus">
                                        <div class="row">
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-model="responsenumberthree" id="responsenumberthree"
                                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                                        @keypress="onlyResponseThreeFormat" autocomplete="off" style="max-width: 12%;" />
                                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                        <input type="text" v-model="responsevaluethree" class="form-control"
                                                            placeholder="Enter Response Value" maxlength="30"
                                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                    </span>
                                                </div>
                                                <div class="custom-error" v-if="v$.responsevaluethree.$error">
                                                    {{ v$.responsevaluethree.$errors[0].$message }}
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <InputSwitch v-model="responsestatusthree" class="mt-1" />
                                                <div class="custom-error" v-if="v$.responsestatusthree.$error">
                                                    {{ v$.responsestatusthree.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-form-group" v-if="fourthrowbtnstatus">
                                        <div class="row text-start">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <button type="button" @click="addMoreFourthRow()" class="btn custom-view-detail-btn"><i
                                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider class="mb-3" v-if="fourthrowshowstatus" />
                                    <!-- Response Four  -->
                                    <div class="custom-form-group" v-if="fourthrowshowstatus">
                                        <div class="row">
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-model="responsenumberfour" id="responsenumberfour"
                                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                                        @keypress="onlyResponseFourFormat" autocomplete="off" style="max-width: 12%;" />
                                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                        <input type="text" v-model="responsevaluefour" class="form-control"
                                                            placeholder="Enter Response Value" maxlength="30"
                                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                    </span>
                                                </div>
                                                <div class="custom-error" v-if="v$.responsevaluefour.$error">
                                                    {{ v$.responsevaluefour.$errors[0].$message }}
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <InputSwitch v-model="responsestatusfour" class="mt-1" />
                                                <div class="custom-error" v-if="v$.responsestatusfour.$error">
                                                    {{ v$.responsestatusfour.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-form-group" v-if="fifthrowbtnstatus">
                                        <div class="row text-start">
                                            <div class="col-lg-12 col-md-12 col-12">
                                                <button type="button" @click="addMoreFifthRow()" class="btn custom-view-detail-btn"><i
                                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider class="mb-3" v-if="fifthrowshowstatus" />
                                    <!-- Response Five  -->
                                    <div class="custom-form-group" v-if="fifthrowshowstatus">
                                        <div class="row">
                                            <div class="col-lg-8 col-md-8 col-12">
                                                <div class="input-group">
                                                    <input type="text" v-model="responsenumberfive" id="responsenumberfive"
                                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                                        @keypress="onlyResponseFiveFormat" autocomplete="off" style="max-width: 12%;" />
                                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                                        <input type="text" v-model="responsevaluefive" class="form-control"
                                                            placeholder="Enter Response Value" maxlength="30"
                                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                                    </span>
                                                </div>
                                                <div class="custom-error" v-if="v$.responsevaluefive.$error">
                                                    {{ v$.responsevaluefive.$errors[0].$message }}
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-12">
                                                <InputSwitch v-model="responsestatusfive" class="mt-1" />
                                                <div class="custom-error" v-if="v$.responsestatusfive.$error">
                                                    {{ v$.responsestatusfive.$errors[0].$message }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="custom-form-group text-center">
                                    <button type="button" class="btn modal-bulk-next-btn" @click="whatsappsubmitbtn()"
                                        :disabled="whtbtnloader">
                                        <span v-if="!whtbtnloader">Submit</span>
                                        <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                            v-if="whtbtnloader">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="intelligent-campagin-modal-header mb-2 text-center">Message Preview</div>
                    </div>
                </div>
                <div class="preview-card-template">
                    <div class="mb-2" v-if="titlename">
                        {{ this.titlename }}
                    </div>
                    <div v-if="headertype == 1 ">
                        <img v-if="whtheaderimage != null" :src="exitwhatheaderpreviewimgurl.objectURL"
                            :style="{ width: '100%' }" alt="" />
                        <img v-if="whtheaderimage == null" src="/assets/images/preview-img.jpg"
                            alt="" :style="{ width: '100%' }" />
                    </div>
                    <div class="preview-body-msg" v-if="whatsappmessage != ''">
                        <p v-html="this.whatsappmessageinput"></p>
                    </div>
                    <Divider v-if="responsestatusone || responsestatustwo || responsestatusthree || responsestatusfour || responsestatusfive" type="dashed" />
                    <button v-if="responsestatusone == true" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{ this.responsenumberone}} - {{
        this.responsevalueone }}</button>
        <button v-if="responsestatustwo == true" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{ this.responsenumbertwo}} - {{
        this.responsevaluetwo }}</button>
        <button v-if="responsestatusthree == true" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{ this.responsenumberthree}} - {{
        this.responsevaluethree }}</button>
        <button v-if="responsestatusfour == true" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{ this.responsenumberfour}} - {{
        this.responsevaluefour }}</button>
        <button v-if="responsestatusfive == true" type="button"
                        class="btn modal-bulk-next-btn w-100 my-1 text-capitalize">{{ this.responsenumberfive}} - {{
        this.responsevaluefive }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, requiredIf, helpers  } from "@vuelidate/validators";
export default {
    data() {
        return {
            v$: useValidate(),
            whtbtnloader: false,
            templatename: '',
            templatealiasname: '',
            titlename: '',
            languagevalue: '',
            headertype: '',
            whtheaderimage: null,
            errorwhtheaderimage: '',
            exitwhatheaderpreviewimgurl: null,
            responsetype: '',
            whatsappmessage: '',
            languagelist: [],
            templatenameMxLimitCount: 250,
            templatenameRemainCountTotal: 250,
            templatebodyMxLimitCount: 250,
            templatebodyRemainCountTotal: 250,
            errStatus: false,
            errmsg: '',
            whatsappmessageinput: '',
            duplicateTemplateMsg : '',

            responsenumberone: 1,
            responsevalueone: '',
            responsestatusone: '',

            responsenumbertwo: 2,
            responsevaluetwo: '',
            responsestatustwo: '',

            responsenumberthree: 3,
            responsevaluethree: '',
            responsestatusthree: '',

            responsenumberfour: 4,
            responsevaluefour: '',
            responsestatusfour: '',

            responsenumberfive: 5,
            responsevaluefive: '',
            responsestatusfive: '',

            switchuncheck: false,
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,

            secondrowshowstatus: false,
            secondrowbtnstatus: true,

            thirdrowshowstatus: false,
            thirdrowbtnstatus: false,

            fourthrowshowstatus: false,
            fourthrowbtnstatus: false,

            fifthrowshowstatus: false,
            fifthrowbtnstatus: false,
        };
    },
    validations() {
        return {
            templatename: {
                required: helpers.withMessage("Please enter template name", required),
            },
            templatealiasname: {
                required: helpers.withMessage("Please enter template name", required),
            },
            languagevalue: {
                required: helpers.withMessage("Please select language", required),
            },
            headertype: {
                required: helpers.withMessage("Please select header", required),
            },
            whtheaderimage: {
                requiredIf: helpers.withMessage(
                    "Please upload image",
                    requiredIf(
                        this.headertype == 1
                    )
                ),
            },
            whatsappmessage: {
                requiredIf: helpers.withMessage(
                    "Please enter message",
                    requiredIf(
                        this.headertype == 0 && this.headertype != ''
                    )
                ),
            },
            responsetype: {
                required: helpers.withMessage("Please select response type", required),
            },
            // response one
            responsevalueone: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsetype == 1
                    )
                ),
            },
            responsestatusone: {
                requiredIf: helpers.withMessage(
                    "Please select status",
                    requiredIf(
                        this.responsetype == 1
                    )
                ),
            },
            // response two
            responsevaluetwo: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatustwo == true
                    )
                ),
            },
            responsestatustwo: {
                requiredIf: helpers.withMessage(
                    "Please select status",
                    requiredIf(
                        this.responsevaluetwo != ''
                    )
                ),
            },
            // response three
            responsevaluethree: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusthree == true
                    )
                ),
            },
            responsestatusthree: {
                requiredIf: helpers.withMessage(
                    "Please select status",
                    requiredIf(
                        this.responsevaluethree != ''
                    )
                ),
            },
            // response four
            responsevaluefour: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusfour == true
                    )
                ),
            },
            responsestatusfour: {
                requiredIf: helpers.withMessage(
                    "Please select status",
                    requiredIf(
                        this.responsevaluefour != ''
                    )
                ),
            },
            // response five
            responsevaluefive: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusfive == true
                    )
                ),
            },
            responsestatusfive: {
                requiredIf: helpers.withMessage(
                    "Please select status",
                    requiredIf(
                        this.responsevaluefive != ''
                    )
                ),
            },
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getlanguages();
    },
    methods: {
        getlanguages(e) {
            this.ApiService.getlanguages(e).then((data) => {
                if (data.status == 200) {
                    this.languagelist = data.data;
                } else {
                    this.languagelist = [];
                }
            });
        },
        showTemplateCountDown: function () {
            this.templatenameRemainCountTotal = this.templatenameMxLimitCount - this.templatename.length;
        },
        showTemplateBodyCountDown: function () {
            this.templatebodyRemainCountTotal = this.templatebodyMxLimitCount - this.whatsappmessage.length;
        },
        whatsappsubmitbtn() {
            this.v$.$validate();
            var formData = new FormData();
            formData.append("cc2", this.templatealiasname);
            formData.append("cc3", this.templatename);
            formData.append("cc17", this.titlename);
            if (this.languagevalue) {
                formData.append('cc6', this.languagevalue.value);
                formData.append('cc7', this.languagevalue.label);
            }
            formData.append("cc9", this.headertype);
            if (this.whtheaderimage) {
                formData.append("cc11", this.whtheaderimage);
            }
            formData.append("cc5", this.whatsappmessage);
            formData.append("cc18", this.responsetype);
            if (this.responsetype == 1) {
                formData.append("cc19", this.responsenumberone);
                formData.append("cc20", this.responsevalueone);
                if (this.responsestatusone == true) {
                    formData.append("cc21", 1);
                } else {
                    formData.append("cc21", 0);
                }
                if (this.responsevaluetwo != '') {
                    formData.append("cc22", this.responsenumbertwo);
                    formData.append("cc23", this.responsevaluetwo);
                    if (this.responsestatustwo == true) {
                        formData.append("cc24", 1);
                    } else {
                        formData.append("cc24", 0);
                    }
                }
                if (this.responsevaluethree != '') {
                    formData.append("cc25", this.responsenumberthree);
                    formData.append("cc26", this.responsevaluethree);
                    if (this.responsestatusthree == true) {
                        formData.append("cc27", 1);
                    } else {
                        formData.append("cc27", 0);
                    }
                }
                if (this.responsevaluefour != '') {
                    formData.append("cc28", this.responsenumberfour);
                    formData.append("cc29", this.responsevaluefour);
                    if (this.responsestatusfour == true) {
                        formData.append("cc30", 1);
                    } else {
                        formData.append("cc30", 0);
                    }
                }
                if (this.responsevaluefive != '') {
                    formData.append("cc31", this.responsenumberfive);
                    formData.append("cc32", this.responsevaluefive);
                    if (this.responsestatusfive == true) {
                        formData.append("cc33", 1);
                    } else {
                        formData.append("cc33", 0);
                    }
                }
            }
            if (!this.v$.$error && !this.duplicateTemplateMsg) {
                this.whtbtnloader = true;
                this.ApiService.addnotificationcampaignTemplate(formData).then((data) => {
                    if (data.status == 200) {
                        this.whtbtnloader = false;
                        this.$store.dispatch("addCampaignStatus");
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.redirectcampaigntemplate();
                    } else {
                        this.whtbtnloader = false;
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                    }
                });
            } else {
                this.$error;
            }
        },
        handleWhtImageUploader() {
            var file = this.$refs.whtheaderimage.files[0];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!file) {
                this.errorwhtheaderimage = 'Please upload image';
            } else if (!file.size > 2048) {
                this.errorwhtheaderimage = 'File size cannot exceed more than 2MB';
            } else if (!file.name.match(allowedExtensions)) {
                this.errorwhtheaderimage = 'Invalid file type';
            } else {
                this.errorwhtheaderimage = '';
                this.whtheaderimage = this.$refs.whtheaderimage.files[0];
                this.exitwhatheaderpreviewimgurl = this.whtheaderimage;
            }
        },
        clearheader() {
            this.whtheaderimage = null;
            this.errorwhtheaderimage = '';
            this.exitwhatheaderpreviewimgurl = null;
        },
        redirectcampaigntemplate() {
            window.location.href = "/campaigntemplate/pushnotification";
        },
        htmlconvertmsg(){
            var messagevalue = this.whatsappmessage;
            this.whatsappmessageinput = messagevalue.replace(/\n/g, '<br/>');
        },
        addMoreSecondRow() {
            this.secondrowshowstatus = true;
            this.secondrowbtnstatus = false;
            this.thirdrowbtnstatus = true;
        },
        addMoreThirdRow() {
            this.thirdrowshowstatus = true;
            this.thirdrowbtnstatus = false;
            this.fourthrowbtnstatus = true;
        },
        addMoreFourthRow() {
            this.fourthrowshowstatus = true;
            this.fourthrowbtnstatus = false;
            this.fifthrowbtnstatus = true;
        },

        addMoreFifthRow() {
            this.fifthrowshowstatus = true;
            this.fifthrowbtnstatus = false;
            this.sixthrowbtnstatus = true;
        },
        checkTemplateName(){
            if((this.templatename).length > 3 && localStorage.getItem('notification_templates') !== null){
                var templates = JSON.parse(localStorage.getItem('notification_templates'));
                for( var i = 0, len = templates.length; i < len; i++ ) {
                    if((templates[i]['cc3']).toLowerCase() === (this.templatename).toLowerCase()) {
                        this.duplicateTemplateMsg = 'Template name already exits.';                        
                        break;
                    }else{
                        this.duplicateTemplateMsg = '';
                    }
                }
            }
        },
        onlyResponseOneFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberone = document.getElementById("responsenumberone");
            if ((responsenumberone.value == '' || responsenumberone.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        onlyResponseTwoFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbertwo = document.getElementById("responsenumbertwo");
            if ((responsenumbertwo.value == '' || responsenumbertwo.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },


        onlyResponseThreeFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberthree = document.getElementById("responsenumberthree");
            if ((responsenumberthree.value == '' || responsenumberthree.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseFourFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberfour = document.getElementById("responsenumberfour");
            if ((responsenumberfour.value == '' || responsenumberfour.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseFiveFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberfive = document.getElementById("responsenumberfive");
            if ((responsenumberfive.value == '' || responsenumberfive.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        clearresponse() {
            this.responsevalueone = '';
            this.responsestatusone = '';

            this.responsevaluetwo = '';
            this.responsestatustwo = '';

            this.responsevaluethree = '';
            this.responsestatusthree = '';

            this.responsevaluefour = '';
            this.responsestatusfour = '';

            this.responsevaluefive = '';
            this.responsestatusfive = '';
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        changeStatusOne(e) {
            if (e == true) {
                this.responsestatusone = 1;
            } else if (e == false) {
                this.responsestatusone = 0;
            }
        }
    },
};
</script>
<style scoped>
.campaign-modal-outer-border {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.intelligent-campagin-modal-header {
    font-family: 'AcuminPro-SemiBold';
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0B294B;
    line-height: 18px;
    margin-top: 10px;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.card-template {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 0px 15px;
}

.preview-card-template {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 14px 15px;
    height: auto;
    min-height: 197px;
}

.preview-body-msg {
    font-family: "AcuminPro-Regular";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 17px;
    margin-top: 15px;
}

.preview-footer-msg {
    margin-top: 15px;
}
.form-box-outer {
    padding: 16px 16px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}
</style>