<template>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-12">
      <div class="voter-history-outer">
        <div class="row mb-2">
          <div
            class="col-lg-12 col-md-12 col-12"
          >
          <div class="voter-label-header">Ambulance Booked</div>
            <div class="voter-label-subheader">03-10-2021 @ 10:AM</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 col-md-10 col-12">
            <div class="voter-body-label">Mr. Mohit Pawar booked our ambulance MH12-KJ-0612 for his father Mr. rajesh pawar from baner to shivaji nagar</div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
              <div class="status-badge status-success text-center">Closed</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-12">
      <div class="voter-history-outer">
        <div class="row mb-2">
          <div
            class="col-lg-12 col-md-12 col-12"
          >
          <div class="voter-label-header">Aadhar Updated</div>
            <div class="voter-label-subheader">05-07-2022 @ 12:00PM</div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-10 col-md-10 col-12">
            <div class="voter-body-label">Mr. Mohit Pawar updated his aadhar along with his wife Rita Pawar @ our Baner Office</div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
              <div class="status-badge status-pending text-center">Progress</div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
</script>
<style scoped>
.voter-history-outer{
    background: #f4f4fa80 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 16px 16px 0;
    margin-bottom: 20px;
    user-select: none;
}
.voter-history-outer .voter-label-header{
    font-family: "AcuminPro-SemiBold";
    font-size: 15px;
    letter-spacing: 0.32px;
    color: #2B2E38;
    line-height: 16px;
}
.voter-history-outer .voter-label-subheader{
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}
.voter-history-outer .voter-body-label{
font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 20px;
}
</style>