<template>
    <div>
                <div v-if="drivingdivdisplayflag ==1">
                  <div class="row">
                      <div class="col-lg-2 col-md-2 col-12 d-flex align-items-center">
                        <div class="customer-label-group">
                          <div class="d-flex align-items-center">
                            <div>
                              <img v-if="LicenseTabDetails.ac17 == null ||
                                LicenseTabDetails.ac17 == ''
                                " src="/assets/images/profile.svg" class="rounded bg-light me-auto d-block" alt=""
                                width="90" />
                              <img :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/drivingphoto/' + LicenseTabDetails.ac17" class="rounded bg-light me-auto d-block" alt=""
                                width="90" v-if="LicenseTabDetails.ac17 != null ||
                                  LicenseTabDetails.ac17 != ''
                                  " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">License Number</label>
                              <div class="from-label-value text-uppercase">
                                {{
                                  LicenseTabDetails.ac3
                                  ? LicenseTabDetails.ac3
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  LicenseTabDetails.ac4
                                  ? LicenseTabDetails.ac4
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Date of Issue</label>
                              <div class="from-label-value">
                                {{ format_date(LicenseTabDetails.ac14) }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Date of Expiry</label>
                              <div class="from-label-value">
                                {{ format_date(LicenseTabDetails.ac13) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Birth</label>
                          <div class="from-label-value">
                            {{ format_date(LicenseTabDetails.ac12) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Gender</label>
                          <div class="from-label-value">
                            {{
                                LicenseTabDetails.ac11
                                ? LicenseTabDetails.ac11 == 1
                                  ? "Male"
                                  : LicenseTabDetails.ac11 == 2
                                    ? "Female"
                                    : "Others"
                                : "N/A"
                              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Father / Husband Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              LicenseTabDetails.ac15
                              ? LicenseTabDetails.ac15
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Permanent Address</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac5 ? LicenseTabDetails.ac5 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Permanent Pincode</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac6 ? LicenseTabDetails.ac6 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Temporary Address</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac7 ? LicenseTabDetails.ac7 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Temporary Pincode</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac8 ? LicenseTabDetails.ac8 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Ola Name</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac9 ? LicenseTabDetails.ac9 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Ola Code</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac10
                              ? LicenseTabDetails.ac10
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div v-if="drivingdivdisplayflag ==0">
                  <div class="row">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="custom-form-group">
                            <label for="formdrivinginput" class="form-label"
                              >Driving Licence No
                              <span class="text-danger">*</span></label
                            >
                            <input
                              type="text"
                              v-model="drivingnumber"
                              class="form-control text-uppercase"
                              id="formdrivinginput"
                              placeholder="Enter Driving Licence No"
                              autocomplete="off"
                              maxlength="15"
                            />
                            <span
                              class="custom-error"
                              v-if="errdriving"
                            >
                              {{ errdriving }}
                            </span>
                            <span
                              class="custom-error"
                              v-if="errmsg"
                            >
                              {{ errmsg }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="
                            col-lg-4 col-md-4 col-12
                          "
                        >
                          <div class="custom-form-group" style="margin-top: 26px;">
                            <button
                              type="button"
                              class="header-btn-outer btn btn-primary"
                              @click="getdrivingdetails()"
                              :disabled="errStatus || drivingnumber.length == 0"
                              :style="{ width: '104px' }"
                            >
                              <span v-if="!showloader">Get Details</span>
                              <div
                                class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                "
                                role="status"
                                v-if="showloader"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
</template>
<script>
import { required, helpers, minLength, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import ApiService from "../../service/ApiService";

export default {
  data() {
    return {
      v$: useValidate(),
      rowId: "",
      AadharTabDetails: "",
      voterTabDetails: "",
      LicenseTabDetails: "",
      PanTabDetails: "",
      ReportChartDetails: "",
      hierarchymodalstatus: false,
      engagementmodalstatus: false,
      maintenermodalstatus: false,
      voterfiltermodalsflag: false,

      lk17: "",
      lk3: "",
      lk23: "",
      voterprofession: null,
      voterprofessionList: [],
      lk20: "",
      lk24: "",
      lk22: "",
      lk19: "",
      lk18: "",
      lk27: "",
      lk26: "",

      messageList: [],
      totalRecords: 0,
      page_no: 0,
      selected_tab: 1,
      viewvoterdetailstatus: false,
      pancardnumber: "",
      aadharcardnumber: "",
      votercardnumber: "",
      drivingnumber: "",
      errpan: "",
      aadharerr: "",
      voteriderr: "",
      errdriving: "",
      errmsg: "",

      aadharotp: "",
      showloader: false,
      timerAadharInterval: null,
      timerAadharOtpStatus: false,
      timerMobileCount: 120,
      aadhar_otp_status: 0,
      showloadermobileotp: false,
      aadharVerifyStatus: false,
      errStatus: false,
      selectedusers: null,
      voterdivdisplayflag: 0,
      drivingdivdisplayflag: 0,
      pandivdisplayflag: 0,
      aadhardivdisplayflag: 0,
      mobile_otp_status: false,
      mobileVerifyStatus: false,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  validations() {
    return {
      aadharotp: {
        required: helpers.withMessage("Enter OTP", required),
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
    };
  },
  watch: {
    drivingnumber(drivingnumber) {
      this.errmsg = "";
      this.validateDrivingNumber(drivingnumber);
      if (drivingnumber.length == 0) {
        this.errdriving = "";
        this.errStatus = false;
      }
    },
  },
  methods: {
    getdrivingdetails() {
      this.errmsg = '';
      this.showloader = true;
      this.ApiService.getdrivinglicensedetails({ ak1: this.rowId, update: 0, drivingnumber: this.drivingnumber }).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.data;
          this.drivingdivdisplayflag = 1;
          this.showloader = false;
        } else {
          this.errmsg = data.message;
          this.drivingdivdisplayflag = 0;
          this.showloader = false;
        }
      });
    },
    getlicensedetails() {
      this.errmsg = '';
      this.ApiService.getlicensedetails({ lk1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.data;
        this.drivingdivdisplayflag = 1;
        } else{
          this.LicenseTabDetails = '';
          this.drivingdivdisplayflag = 0;
        }
      });
    },
    validateDrivingNumber(e) {
      this.errStatus = false;
      if (!this.validDrivingNo(e)) {
        this.errdriving = "Please enter valid driving licence no";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errdriving = "";
        return true;
      }
    },

    validDrivingNo: function (e) {
      var re = /([A-Za-z]){2}([0-9]){13}$/;
      return re.test(e);
    },
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}
.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}
.company-detail-box-outer {
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}
.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}
.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}
.customer-label-group {
  margin-bottom: 20px;
}
.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}
.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
}
.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 520px;
}
.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}
.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}
.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}
.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}
.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}
.text-star-blank {
  color: #c1c5c9;
}
.voter-report-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 12px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-top: 0;
}
.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}
.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}
</style>