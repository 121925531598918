import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config' 
import VueToast from 'vue-toast-notification';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../public/assets/css/style.css';
import '../public/assets/css/fonts.css';
import '../public/assets/css/sidebars.css';
import '../public/assets/css/login.css';
import '../public/assets/css/customform.css';
import '../public/assets/css/calendar.css';
import '../public/assets/css/datatable.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import Multiselect from '@vueform/multiselect'
import FullCalendar from './fullcalendar';
import '@vueform/multiselect/themes/default.css';
import global from "@/global";
import OrganizationCharts from "./components/modal/OrganizationChart.vue"
import EngagementTotalVoterModal from "./components/modal/EngagementTotalVoterModal.vue"
import MaintenerTotalVoterModal from "./components/modal/MaintenerTotalVoterModal.vue"
import SubOrganizationChart from "./components/modal/SubOrganizationChart.vue"
import EditDrivingDetailModalTabs from "./pages/voterstabs/EditDrivingDetailModalTabs.vue"
import VoterSchemeDetailModalTabs from "./pages/voterstabs/VoterSchemeDetailModalTabs.vue"
import VoterHistoryDetailsModalaTabs from "./pages/voterstabs/VoterHistoryDetailsModalaTabs.vue"

import AudioCallCampaignTab from "./components/templatestabs/AudioCallCampaignTab.vue";
import EmailCampaignTab from "./components/templatestabs/EmailCampaignTab.vue";
import iMessageCampaignTab from "./components/templatestabs/iMessageCampaignTab.vue";
import IntelligentCampaignTab from "./components/templatestabs/IntelligentCampaignTab.vue";
import SMSCampaignTab from "./components/templatestabs/SMSCampaignTab.vue";
import WhatsAppCampaignTab from "./components/templatestabs/WhatsAppCampaignTab.vue";
import PushNotificationCampaignTab from "./components/templatestabs/PushNotificationCampaignTab.vue";

import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import InputSwitch from 'primevue/inputswitch';
import Calendar from 'primevue/calendar';
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import Chart from 'primevue/chart';
import Editor from 'primevue/editor';
import OverlayPanel from 'primevue/overlaypanel';
import Listbox from 'primevue/listbox';
import Tree from 'primevue/tree';
import OrganizationChart from 'primevue/organizationchart';


// import '@fullcalendar/core/main.min.css';
// import '@fullcalendar/daygrid/main.min.css';
// import '@fullcalendar/timegrid/main.min.css';
import 'primeicons/primeicons.css';
import VueNumberFormat from 'vue-number-format';
import StarRating from 'vue-star-rating';

// const app = createApp(App)
const app = createApp({
    render () { return h(App); },
    provide: { global },
});
app.component('StarRating', StarRating)
app.component("Multiselect", Multiselect);
app.component('FullCalendar', FullCalendar);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('Chart', Chart);
app.component('Editor', Editor);
app.component('DataTable', DataTable);
app.component('Button', Button);
app.component('Column', Column);
app.component('Dropdown', Dropdown);
app.component('Checkbox', Checkbox);
app.component('InputSwitch', InputSwitch);
app.component('Calendar', Calendar);
app.component('Divider', Divider);
app.component('FileUpload', FileUpload);
app.component('ProgressBar', ProgressBar);
app.component('OrganizationCharts', OrganizationCharts);
app.component('EngagementTotalVoterModal', EngagementTotalVoterModal);
app.component('MaintenerTotalVoterModal', MaintenerTotalVoterModal);
app.component('SubOrganizationChart', SubOrganizationChart);
app.component('EditDrivingDetailModalTabs', EditDrivingDetailModalTabs);
app.component('VoterSchemeDetailModalTabs', VoterSchemeDetailModalTabs);
app.component('VoterHistoryDetailsModalaTabs', VoterHistoryDetailsModalaTabs);
app.component('OverlayPanel', OverlayPanel);
app.component('Listbox', Listbox);
app.component('Tree', Tree);
app.component('OrganizationChart', OrganizationChart);
app.component('AudioCallCampaignTab', AudioCallCampaignTab);
app.component('EmailCampaignTab', EmailCampaignTab);
app.component('iMessageCampaignTab', iMessageCampaignTab);
app.component('IntelligentCampaignTab', IntelligentCampaignTab);
app.component('SMSCampaignTab', SMSCampaignTab);
app.component('WhatsAppCampaignTab', WhatsAppCampaignTab);
app.component('PushNotificationCampaignTab', PushNotificationCampaignTab);

app.use(router)
app.use(store)
app.use(PrimeVue)
app.use(VueNumberFormat)
app.use(VueToast)
app.mount('#app')
app.config.devtools = true;

