<template>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="custom-form-group">
                <label class="intelligent-campagin-modal-header">Email Campaign</label>
            </div>
        </div>
    </div>
    <div class="campaign-tab-outer-scroll" :style="{ height: '409px' }">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Template Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="templatename" class="form-control" placeholder="Enter Template Name"
                        maxlength="250" autocomplete="off" />
                    <div class="custom-error" v-if="v$.templatename.$error">
                        {{ v$.templatename.$errors[0].$message }}
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label for="formmessageInput" class="form-label">Message <span class="text-danger">*</span></label>
                    <textarea type="text" v-model="emailmessage" class="form-control" id="formmessageInput"
                        placeholder="Enter Message" autocomplete="off" rows="3"></textarea>
                    <div class="custom-error" v-if="v$.emailmessage.$error">
                        {{ v$.emailmessage.$errors[0].$message }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="custom-form-group text-center">
                    <button type="button" class="btn modal-bulk-next-btn" @click="emailsubmitbtn()"
                        :disabled="emailgbtnloader">
                        <span v-if="!emailgbtnloader">Submit</span>
                        <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                            v-if="emailgbtnloader">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
    data() {
        return {
            v$: useValidate(),
            emailgbtnloader: false,
            templatename: '',
            emailmessage: '',
        };
    },
    validations() {
        return {
            templatename: {
                required: helpers.withMessage("Please enter template name", required),
            },
            emailmessage: {
                required: helpers.withMessage("Please enter message", required),
            },
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
    },
    methods: {
        emailsubmitbtn() {
            this.v$.$validate();
            var formData = new FormData();
            formData.append("al2", this.templatename);
            formData.append("al3", this.emailmessage);
            if (!this.v$.$error) {
                this.$store.dispatch("addCampaignStatus");
            } else {
                this.$error;
            }
        },
    },
};
</script>
<style scoped>
.campaign-modal-outer-border {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.intelligent-campagin-modal-header {
    font-family: 'AcuminPro-SemiBold';
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0B294B;
    line-height: 18px;
    margin-top: 10px;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}
</style>