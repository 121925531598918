import { createRouter, createWebHistory } from 'vue-router';
const routes = [

  {
    path: '/',
    name: 'Home',
    exact: true,
    component: () =>
      import('./pages/AccessCode.vue'),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/login',
    name: 'Login',
    exact: true,
    component: () =>
      import('./pages/Login.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    exact: true,
    component: () =>
      import('./pages/ForgotPassword.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/accesscode',
    name: 'AccessCode',
    exact: true,
    component: () =>
      import('./pages/AccessCode.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    component: () =>
      import('./pages/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings/designation',
    name: 'ClientDesignation',
    component: () =>
      import('./pages/settings/Designation.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/karyakartas/list',
    name: 'KaryakartasList',
    component: () =>
      import('./pages/karyakartas/KaryakartasList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/karyakartas/report',
    name: 'KaryakartasReports',
    component: () =>
      import('./pages/karyakartas/KaryakartasReports.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/karyakartas/add',
    name: 'Newkaryakartas',
    component: () =>
      import('./pages/karyakartas/AddKaryakartas.vue'),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/voters/addusingaadhar',
    name: 'AddUsingAadhar',
    component: () =>
      import('./pages/voters/AddUsingAadhar.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/addusingepic',
    name: 'AddUsingEpic',
    component: () =>
      import('./pages/voters/AddUsingEpic.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/addusingmanual',
    name: 'AddUsingManual',
    component: () =>
      import('./pages/voters/AddUsingManual.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/birthdays/todays',
    name: 'TodayBirthDateList',
    component: () =>
      import('./pages/birthdays/TodayBirthDateList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/birthdays/tomorrow',
    name: 'TomorrowBirthDateList',
    component: () =>
      import('./pages/birthdays/TomorrowBirthDateList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/birthdays/thisweek',
    name: 'ThisWeekBirthDateList',
    component: () =>
      import('./pages/birthdays/ThisWeekBirthDateList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/birthdays/thismonth',
    name: 'ThisMonthBirthDateList',
    component: () =>
      import('./pages/birthdays/ThisMonthBirthDateList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/search',
    name: 'SearchVoter',
    component: () =>
      import('./pages/voters/SearchVoter.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/myvoters',
    name: 'MyTotalListing',
    component: () =>
      import('./pages/voters/MyVoters.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/myvoterreport',
    name: 'MyTotalReport',
    component: () =>
      import('./pages/voters/MyVoterReport.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/teamsvoters',
    name: 'TeamTotalListing',
    component: () =>
      import('./pages/voters/TeamsVoters.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/teamsvoterreport',
    name: 'TeamTotalReport',
    component: () =>
      import('./pages/voters/TeamsVoterReport.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/incomplete',
    name: 'IncompleteVoter',
    component: () =>
      import('./pages/voters/IncompleteVoter.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/mismatchedbyaddress',
    name: 'MismatchedByAddress',
    component: () =>
      import('./pages/voters/MismatchedByAddress.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/mismatchedbyname',
    name: 'MismatchedByName',
    component: () =>
      import('./pages/voters/MismatchedByName.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/import',
    name: 'ImportVoter',
    component: () =>
      import('./pages/ImportVoter.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: '/campaign',
    name: 'VoterCampaign',
    component: () =>
      import('./pages/campaign/CampaignList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/whatsapplogs/:campaignId?',
    name: 'VoterWhatsAppCampaignLogs',
    component: () =>
      import('./pages/campaign/WhatsAppCampaignLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/smslogs/:campaignId?',
    name: 'VoterSmsCampaignLogs',
    component: () =>
      import('./pages/campaign/SmsCampaignLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/calllogs/:campaignId?',
    name: 'VoterCallCampaignLogs',
    component: () =>
      import('./pages/campaign/CallCampaignLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/pushlogs/:campaignId?',
    name: 'VoterPushCampaignLogs',
    component: () =>
      import('./pages/campaign/PushCampaignLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/logs/:campaignId?',
    name: 'VoterCampaignLogs',
    component: () =>
      import('./pages/campaign/CampaignLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/report/:campaignId?',
    name: 'VoterCallCampaignReports',
    component: () =>
      import('./pages/campaign/CallCampaignReports.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/report/:campaignId?',
    name: 'VoterSmsCampaignReports',
    component: () =>
      import('./pages/campaign/SmsCampaignReports.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaign/report/:campaignId?',
    name: 'VoterCampaignReports',
    component: () =>
      import('./pages/campaign/CampaignReports.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplate/intelligent',
    name: 'IntelligentCampaignListing',
    component: () =>
      import('./pages/campaigntemplatelist/IntelligentCampaignListing.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplate/whatsapp',
    name: 'WhatsAppCampaignListing',
    component: () =>
      import('./pages/campaigntemplatelist/WhatsAppCampaignListing.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplate/rcsimessage',
    name: 'iMessageCampaignListing',
    component: () =>
      import('./pages/campaigntemplatelist/iMessageCampaignListing.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplate/sms',
    name: 'SMSCampaignListing',
    component: () =>
      import('./pages/campaigntemplatelist/SMSCampaignListing.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplate/email',
    name: 'EmailCampaignListing',
    component: () =>
      import('./pages/campaigntemplatelist/EmailCampaignListing.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplate/audiocall',
    name: 'AudioCallCampaignListing',
    component: () =>
      import('./pages/campaigntemplatelist/AudioCallCampaignListing.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplate/pushnotification',
    name: 'PushNotificationCampaignListing',
    component: () =>
      import('./pages/campaigntemplatelist/PushNotificationCampaignListing.vue'),
    meta: {
      requiresAuth: true,
    },
  },



  {
    path: '/billing/dashboard',
    name: 'BillingDashboard',
    component: () =>
      import('./pages/billing/Dashboard.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/billing/invoice',
    name: 'BillingTaxInvoice',
    component: () =>
      import('./pages/billing/TaxInvoice.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/billing/statement',
    name: 'BillingAccountStatement',
    component: () =>
      import('./pages/billing/AccountStatement.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/billing/receipt',
    name: 'BillingPaymentReceipt',
    component: () =>
      import('./pages/billing/PaymentReceipt.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/billing/changeplanrequest',
    name: 'ChangePlanRequest',
    component: () =>
      import('./pages/billing/ChangePlanRequest.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/myprofile',
    name: 'MyProfile',
    component: () =>
      import('./pages/MyProfile.vue'),

    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/sms',
    name: 'SMSLogs',
    component: () =>
      import('./pages/systemlogs/SmsLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/whatsapplogs',
    name: 'WhatsAppLogs',
    component: () =>
      import('./pages/systemlogs/WhatsAppLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/webbotlogs',
    name: 'WebBotLogs',
    component: () =>
      import('./pages/systemlogs/WebBotLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/email',
    name: 'EmailLogs',
    component: () =>
      import('./pages/systemlogs/EmailLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/myincoming',
    name: 'MyIncomingLogs',
    component: () =>
      import('./pages/systemlogs/MyIncomingLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/teamsincoming',
    name: 'TeamsIncomingLogs',
    component: () =>
      import('./pages/systemlogs/TeamsIncomingLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/myoutgoing',
    name: 'MyOutgoingLogs',
    component: () =>
      import('./pages/systemlogs/MyOutgoingLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/aadhar-v1-card',
    name: 'AadharV1ApiLogs',
    component: () =>
      import('./pages/systemlogs/AadharV1Logs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/aadhar-v2-card',
    name: 'AadharV2ApiLogs',
    component: () =>
      import('./pages/systemlogs/AadharV2Logs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/voter/_search',
    name: 'VoterCardSearchLogs',
    component: () =>
      import('./pages/systemlogs/VoterCardSearchLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/voter/_fetch',
    name: 'VoterCardFetchLogs',
    component: () =>
      import('./pages/systemlogs/VoterCardFetchLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/pan-card',
    name: 'PanCardLogs',
    component: () =>
      import('./pages/systemlogs/PanCardLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/driving-licence',
    name: 'DrivingLicenceLogs',
    component: () =>
      import('./pages/systemlogs/DrivingLicenceLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/passport',
    name: 'PassportLogs',
    component: () =>
      import('./pages/systemlogs/PassportLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/importvoter',
    name: 'ImportVoterLogs',
    component: () =>
      import('./pages/systemlogs/ImportVoterLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/logs/teamsoutgoing',
    name: 'TeamsOutgoingLogs',
    component: () =>
      import('./pages/systemlogs/TeamsOutgoingLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/add',
    name: 'NewUser',
    component: () =>
      import('./pages/Users/AddUser.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/listing',
    name: 'UserListing',
    component: () =>
      import('./pages/Users/ListUsers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user/permissionsets',
    name: 'UserRole',
    component: () =>
      import('./pages/Users/UsersRole.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/companydetails',
    name: 'CompanyDetails',
    component: () =>
      import('./pages/CompanyDetails.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/tags',
    name: 'Tags',
    component: () =>
      import('./pages/Tags.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/eventsettings/sms',
    name: 'SMSEventSettings',
    component: () =>
      import('./pages/eventsettings/SmsEventSettingsList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/eventsettings/email',
    name: 'EmailEventSettings',
    component: () =>
      import('./pages/eventsettings/EmailEventSettingsList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cloudtelephony/virtualnumbers',
    name: 'VirtualNumbers',
    component: () =>
      import('./pages/cloudtelephony/VirtualNumbers.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cloudtelephony/virtualnumbers/set/:param1/:param2',
    name: 'VirtualNumberSet',
    component: () =>
      import('./pages/cloudtelephony/VirtualNumberSet.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cloudtelephony/virtualnumbers/existset/:param1/:param2',
    name: 'VirtualNumberExistSet',
    component: () =>
      import('./pages/cloudtelephony/VirtualNumberExistSet.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cloudtelephony/customtunes',
    name: 'CustomTunes',
    component: () =>
      import('./pages/cloudtelephony/CustomTunes.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)',
    name: 'notfound',
    component: () =>
      import('./pages/NotFound'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/underprogress',
    name: 'underprogress',
    exact: true,
    component: () =>
      import('./pages/UnderProgress.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voters/unassigned',
    name: 'UnassignedVoters',
    component: () =>
      import('./pages/voters/UnassignedVoters.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/support/new',
    name: 'NewTicket',
    component: () =>
      import('./pages/support/NewTicket.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/support/open',
    name: 'OpenTicket',
    component: () =>
      import('./pages/support/OpenTicket.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/support/closed',
    name: 'ClosedTicket',
    component: () =>
      import('./pages/support/ClosedTicket.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/support/cancelled',
    name: 'CancelledTicket',
    component: () =>
      import('./pages/support/CancelledTicket.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/demo',
    name: 'DemoPage',
    component: () =>
      import('./pages/DemoPage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/chatbot',
    name: 'ChatBotLandingPage',
    exact: true,
    component: () =>
      import('./pages/chatbot.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/webbot/:param1',
    name: 'WebBot',
    exact: true,
    component: () =>
      import('./pages/WebBot.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/opensms',
    name: 'OpenSms',
    component: () =>
      import('./pages/OpenSms.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/myprofile/userdelete',
    name: 'ProfileDelete',
    component: () =>
      import('./pages/MyProfileDelete.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/:emailcode',
    name: 'EmailVerify',
    exact: true,
    component: () =>
      import('./pages/EmailVerify.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/jantadarbar',
    name: 'JantaDarbarList',
    component: () =>
      import('./pages/jantadarbar/JantaDarbarList.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/jantadarbarparticioant/:eventId?',
    name: 'VoterParticioantLogs',
    component: () =>
      import('./pages/jantadarbar/ParticioantLogs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/approvals/myrequest',
    name: 'MyRequest',
    component: () =>
      import('./pages/requestapprovals/MyRequest.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/approvals/teamsrequest',
    name: 'TeamsRequest',
    component: () =>
      import('./pages/requestapprovals/TeamsRequest.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/approvals/approverequest',
    name: 'RequestsToApproveTag',
    component: () =>
      import('./pages/requestapprovals/RequestsToApproveTag.vue'),
    meta: {
      requiresAuth: true,
    },
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const authenticatedUser = localStorage.user_reference;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const checkModuleAccess = to.matched.some(record => record.meta.checkModuleAccess);
  const moduleId = to.meta.moduleId;
  const permission_fks = [1, 11, 12];

  if (requiresAuth && !authenticatedUser) {
    next('/');
  }
  else {
    if (checkModuleAccess) {
      if (permission_fks.includes(moduleId)) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }

  }

});

export default router