<template>
  <div>
    <table
      class="table custom-overview-table-outer align-middle table-responsive"
    >
      <thead>
        <tr>
          <td scope="col" style="width: 22%">Name</td>
          <td scope="col" style="width: 27%">Location</td>
          <td scope="col" style="width: 24%">Details</td>
          <td scope="col" style="width: 27%">Action</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 22%">
            <div>
              <div class="mb-1">Mr. Manoj Pawar</div>
              <small class="sub-table-tr">42 Y | M</small>
            </div>
          </td>
          <td style="width: 27%">
            <div>
              <div class="mb-1">Gokul Nagar, Nagala Park,</div>
              <div>Pune - 416012</div>
            </div>
          </td>
          <td style="width: 24%">
            <div>
              <div class="mb-1">Corporator</div>
              <small class="sub-table-tr">Pune Municipal Corporation</small>
            </div>
          </td>
          <td style="width: 27%">
            <div class="d-flex align-items-end">
            <button class="btn custom-outline-call-btn mx-2">
              <i class="pi pi-phone call-btn-color"></i></button>
            <!-- <button class="btn custom-outline-whatsapp-btn me-2">
              <i class="pi pi-whatsapp whatsapp-color"></i>
            </button> -->
            <button
              type="button"
              class="btn custom-view-detail-btn"
              @click="hierarchymodalopen()"
            >
              View Profile
            </button>
            </div>
          </td>
        </tr>
        <tr>
          <td style="width: 22%">
            <div>
              <div class="mb-1">Mr. Dheeraj Kamble</div>
              <small class="sub-table-tr">52 Y | M</small>
            </div>
          </td>
          <td style="width: 27%">
            <div>
              <div class="mb-1">Sai Siddhi Road, Nagala Park,</div>
              <div>Pune - 416012</div>
            </div>
          </td>
          <td style="width: 24%">
            <div>
              <div class="mb-1">Bank Board Of Directors</div>
              <small class="sub-table-tr">Co-operative Sahakari Bank</small>
            </div>
          </td>
          <td style="width: 27%">
            <div class="d-flex align-items-end">
            <button class="btn custom-outline-call-btn mx-2">
              <i class="pi pi-phone call-btn-color"></i></button>
            <!-- <button class="btn custom-outline-whatsapp-btn me-2">
              <i class="pi pi-whatsapp whatsapp-color"></i>
            </button> -->
            <button
              type="button"
              class="btn custom-view-detail-btn"
              disabled
            >
              View Profile
            </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-mask" v-if="mainternerprofilemodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Family Hierarchy</h5>
          <button
            type="button"
            class="btn-close"
            @click="closermainternerprofilemodal()"
          ></button>
        </div>
        <SubOrganizationChart />
      </div>
    </div>
  </div>
</template>
            
<script>
export default {
  data() {
    return {
      mainternerprofilemodalstatus: false,
    };
  },
  methods: {
    hierarchymodalopen() {
      this.mainternerprofilemodalstatus = true;
    },
    closermainternerprofilemodal() {
      this.mainternerprofilemodalstatus = false;
    },
  },
};
</script>           
<style>
/* custom table css start here  */
.custom-overview-table-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.custom-overview-table-outer thead {
  background: #f2f4fb 0% 0% no-repeat padding-box;
}
.custom-overview-table-outer thead tr td,
.custom-overview-table-outer thead tr th {
  border-bottom-width: 0px;
}
.custom-overview-table-outer thead tr td,
.custom-overview-table-outer thead tr th {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
}
.custom-overview-table-outer tbody tr td,
.custom-overview-table-outer tbody tr th {
  font-family: "AcuminPro-SemiBold";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
}
.custom-overview-table-outer thead tr td,
.custom-overview-table-outer thead tr th {
  padding: 10px 16px;
}
.custom-overview-table-outer tbody tr td,
.custom-overview-table-outer tbody tr th {
  padding: 16px;
}
.custom-overview-table-outer tbody tr td .table-heading {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}
.custom-overview-table-outer tbody tr td .table-heading,
.custom-overview-table-outer tbody tr td .table-subheading {
  padding-bottom: 8px;
}
.custom-view-detail-btn {
  color: #5266e5;
  border-color: #5266e5;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #5266e5;
  border-radius: 4px;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
}
.custom-view-detail-btn:hover {
  color: #5266e5;
}
.custom-view-detail-btn:focus-visible {
  outline: 0;
}
table.custom-overview-table-outer {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.custom-overview-table-outer thead {
  flex: 0 0 auto;
}

.custom-overview-table-outer tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 194px;
}

.custom-overview-table-outer tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}
.custom-overview-table-outer tbody::-webkit-scrollbar {
  display: block;
}

.custom-overview-table-outer tbody::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.custom-overview-table-outer tbody::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.custom-spinner-loader-btn {
  width: 15px;
  height: 15px;
  border: 2px solid #ced9e9;
  border-right-color: transparent;
}
/* custom table css end here  */
.user-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.user-badge.status-active {
  background: #c8e6c9;
  color: #256029;
}

.user-badge.status-per-suspend {
  background: #ffcdd2;
  color: #c63737;
}
.user-badge.status-temp-suspend {
  background: #ffd8b2;
  color: #805b36;
}
.sub-table-tr {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
}
.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}
.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}
.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}
.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}
</style>